import { useTheme } from '@emotion/react';
import { Avatar } from '@mui/material';
import { Box } from '@mui/system';
import { IconFileSpreadsheet } from '@tabler/icons-react';
import { useSelector } from 'react-redux';

const SheetLinkSection = () => {
    const { savedGSheetURL } = useSelector((state) => state.sankey);
    const theme = useTheme();
    const handleRoute = () => {
        if (savedGSheetURL) window.open(savedGSheetURL, '_blank');
    };
    return (
        <Box
            sx={{
                ml: 2,
                [theme.breakpoints.down('lg')]: {
                    mr: 2
                }
            }}
        >
            <Avatar
                variant="rounded"
                sx={{
                    ...theme.typography.commonAvatar,
                    ...theme.typography.mediumAvatar,
                    transition: 'all .2s ease-in-out',
                    background: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.secondary.light,
                    color: theme.palette.mode === 'dark' ? theme.palette.warning.dark : theme.palette.secondary.dark,
                    '&[aria-controls="menu-list-grow"],&:hover': {
                        background: theme.palette.mode === 'dark' ? theme.palette.warning.dark : theme.palette.secondary.dark,
                        color: theme.palette.mode === 'dark' ? theme.palette.grey[800] : theme.palette.secondary.light
                    }
                }}
                aria-haspopup="true"
                onClick={handleRoute}
                color="inherit"
            >
                <IconFileSpreadsheet stroke={1.5} size="20px" />
            </Avatar>
        </Box>
    );
};
export default SheetLinkSection;
