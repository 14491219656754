import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import axios from 'axios';
import ValidationWizard from './ValidationWizard';
import { DialogContent, DialogTitle } from '@mui/material';
import { useAuth } from 'utils/route-guard/OutsetaGuard';

export default function AlertDialog() {
  const [open, setOpen] = React.useState(false);
  const {user} = useAuth();
    React.useEffect(() => {
        getUserProfileSettings()
    }, []);

  const handleClose = (event, reason) => {
    if (reason && reason === "backdropClick") 
        return;
    setOpen(false);
  };

  const getUserProfileSettings = () => {
    let datas = {
        id: user.Uid,
    };
    // debugger; // eslint-disable-line no-debugger
    axios({
        method: 'post',
        url: process.env.REACT_APP_SERVER_URL + 'api/getOutsetaUserProfileSettings',
        data:datas
    })
        .then(function (response) {
            if(response.data === false){
                setOpen(true)
            }
        })
        .catch(function (err) {
            console.log(err);
        });
};

  return (
    <React.Fragment>
    
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth={"sm"}
        fullWidth
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        disableEscapeKeyDown={true}
      >
       <DialogTitle id="alert-dialog-title">
          {"Welcome User"}
        </DialogTitle>
        <DialogContent>
        <ValidationWizard handleClose={handleClose} />
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}