import {  useNavigate } from "react-router";
import { useAuth as defaultAuth } from "./OutsetaGuard";
import Loader from 'ui-component/Loader';


const PLANS = {
//   PRO: { label: "Pro", uid: "yW1qbyWB" },
  BASIC: { label: "Free Plan", uid: "MQvGLo9Y" }
};

function hasCorrectPlan(plans, user) {
  if (user) {
    const planIdForUser = user.Account?.CurrentSubscription?.Plan?.Uid;
    return !!plans.find((plan) => plan.uid === planIdForUser);
  } else {
    return false;
  }
}


export default function ProtectedRoute({ pro, children }) {
    const navigate = useNavigate();
  const { user, openProfile, isLoading } = defaultAuth();


  // Pro routes only accessible with pro plan
  // Basic routes accessible with basic or pro plan
  const plansWithAccess = pro ? [PLANS.PRO] : [PLANS.BASIC, PLANS.PRO];
  const allowAccess = hasCorrectPlan(plansWithAccess, user);

//   if (isLoading) return <p>Authenticating...</p>;
  if (isLoading) return <Loader />;

  if (allowAccess) {
    console.log(user)
    return children;
  } else if (user) {
    return (
      <>
        <p>
          To access this content you need to upgrade to the{" "}
          <strong>{plansWithAccess[0].label}</strong> plan.
        </p>
        <button onClick={() => openProfile({ tab: "planChange" })}>
          Upgrade
        </button>
      </>
    );
  } else {
   return navigate('/')
    // return (
    //   <>
    //     <p>
    //       To access this content you need to{" "}
    //       <button onClick={openSignup}>signup</button> for the{" "}
    //       <strong>{plansWithAccess[0].label}</strong> plan.
    //     </p>

    //     <p>
    //       Or <button onClick={openLogin}>login</button> if you already have an
    //       account.
    //     </p>
    //   </>
    // );
  }
}
