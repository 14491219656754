import * as React from 'react';

// material-ui
import {  Grid, TextField, Typography } from '@mui/material';
// import { IconFileSpreadsheet } from '@tabler/icons-react';

// ==============================|| FORM WIZARD - VALIDATION ||============================== //

export default function GSheetTerms({setConnectToSheet,connectToSheet,setGSheetURLCheck , gSheetURLCheck , setMapHeaders, MapHeaders,setMappingHeadersError,mappingHeadersError}) {


    const checksheetVal = (e) => {
        setConnectToSheet(e.target.value)
        setGSheetURLCheck(false)
    }

    console.log(connectToSheet)
    
  
    const onChangeMappingHeaders = (e) => { 
        if(e.target.value === ""){
            setMappingHeadersError([...mappingHeadersError,e.target.name])
        }
        if(mappingHeadersError.includes(e.target.name)){
            let copy = [...mappingHeadersError];
            let newarr = copy.filter(x => x !== e.target.name);
            setMappingHeadersError(newarr)
        }
        setMapHeaders({
            ...MapHeaders,
            [e.target.name]:e.target.value
        })
    }
    return (
        <>
            <Typography variant="h3" gutterBottom sx={{ mb: 2 }}>
                Intelligent Money Hub - Gsheet
            </Typography>
            <Typography variant="body1" gutterBottom sx={{ mb: 2 }}>
                The Intelligent Money Hub accesses you data from Google Sheets. We do not store or retain your data. This ensures you have complete control over your data at all times.
            </Typography>
            <ul>
                {/* <li style={{listStyleType:'decimel'}}>
                    <Typography variant="body1" gutterBottom sx={{ mb: 2 }}>
                    Click on G-Sheet &nbsp; &nbsp; &nbsp;  
                    <div style={{display:'inline-flex' , alignItems:'center'}}><IconFileSpreadsheet color="green" />{' '} <Typography
                                    href="https://docs.google.com/spreadsheets/d/1L5Awg4zHbhhMrE3qUl-YYSApp8jQH_CHdI8UUrjSsHA/edit?pli=1#gid=1022626307"
                                    target="_blank"
                                    component={Link}
                                    underline="always"
                                    color={'blue'}
                                    style={{ fontSize: '15px' }}
                                    variant="h5"
                                >
                                    Intelligent Money Hub G-Sheet V1.0
                                </Typography></div>
                                &nbsp;&nbsp;&nbsp; and Take a Copy.
                    The Sheet has some dummy data for you to familiarise yourself with Intelligent money hub
                    </Typography>
                </li> */}
                <li style={{listStyleType:'decimel'}}>
                    <Typography variant="body1" gutterBottom sx={{ mb: 2 }}>
                  Paste Your G-Sheet URL here to Connect to Intelligent Money Hub
                    </Typography>
                </li>
                <li style={{listStyleType:'decimel'}}>
                    <Typography variant="body1" gutterBottom sx={{ mb: 2 }}>
                  Add Mapping Headers (ALL fields are required )
                    </Typography>
                </li>
            </ul>

                      
                            <Grid container spacing={3}>
                            <Grid item xs={12}>
                            <TextField
                                label="Gsheet URL"
                                fullWidth
                                name="sheet"
                                value={connectToSheet || ""}
                                onChange={checksheetVal}
                                error={gSheetURLCheck}
                                helperText={gSheetURLCheck ? "Gsheet URL Is Required" : ""}
                            >
                            
                            </TextField>
                            </Grid>
                            <Grid item xs={12}>
                                <TextField fullWidth id="outlined-basic" name='map_date' 
                                error={mappingHeadersError.includes("map_date")}
                                helperText={mappingHeadersError.includes("map_date") ? "Date Header Is Required" : ""}
                                value={MapHeaders.map_date} onChange={onChangeMappingHeaders} label="Date Header Title" />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField fullWidth 
                                error={mappingHeadersError.includes("map_amount")}
                                helperText={mappingHeadersError.includes("map_amount") ? "Amount Header Is Required" : ""}
                                id="outlined-basic" name='map_amount' value={MapHeaders.map_amount} onChange={onChangeMappingHeaders} label="Amount Header Title" />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                error={mappingHeadersError.includes("map_description")}
                                helperText={mappingHeadersError.includes("map_description") ? "Description Header Is Required" : ""}
                                 fullWidth id="outlined-basic" name='map_description' value={MapHeaders.map_description} onChange={onChangeMappingHeaders} label="Description Header Title" />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField error={mappingHeadersError.includes("map_category")}
                                helperText={mappingHeadersError.includes("map_category") ? "Category Header Is Required" : ""}
                                 fullWidth id="outlined-basic" name='map_category' value={MapHeaders.map_category} onChange={onChangeMappingHeaders} label="Category Header Title" />
                            </Grid>
                            {/* <Grid item xs={12}>
                                <TextField fullWidth id="outlined-basic" name='map_categoryGroup' value={MapHeaders.map_categoryGroup} onChange={onChangeMappingHeaders} label="Category Group Header Title" />
                            </Grid> */}
                            <Grid item xs={12}>
                                <TextField error={mappingHeadersError.includes("map_account")}
                                helperText={mappingHeadersError.includes("map_account") ? "Account Header Is Required" : ""}
                                 fullWidth id="outlined-basic" name='map_account' value={MapHeaders.map_account} onChange={onChangeMappingHeaders} label="Account Header Title" />
                            </Grid>
                        </Grid>
        </>
    );
}
