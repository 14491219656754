import { useTheme } from '@emotion/react';
import { Avatar } from '@mui/material';
import { Box } from '@mui/system';
import { IconRefresh } from '@tabler/icons-react';
import { showenqueSnackbar } from 'Helpers/gsheetHelpers';

const RefreshSection = () => {
    const theme = useTheme();
    const getGsheetAcc = async () => {
        try {
            const response = await fetch(process.env.REACT_APP_SERVER_URL + 'auth/google/sheet');
            if (response.ok) {
                const data = await response.json();
                // setConsentUrl(data.consentUrl);

                // Open the consent URL in a new window
                window.open(data.authorizationUrl, '_blank', 'location=yes,height=570,width=520,scrollbars=yes,status=yes');
                // Listen for messages from the popup window
                window.addEventListener('message', (event) => {
                    // Check if the message is from the consent window and contains authentication success information
                    if (event.data && event.data.success) {
                        if (event.data.token) {
                            localStorage.setItem('gToken', JSON.stringify(event.data.token));
                            localStorage.setItem('userToken',event.data.userToken);
                        }
                        // Handle the authentication success here
                        showenqueSnackbar('Gsheet Access Granted! please Add Gsheet URL', 'success');

                        // You can update your state or perform other actions here
                    }
                });
            } else {
                console.error('API request failed');
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };
    return (
        <Box
            sx={{
                ml: 2,
                [theme.breakpoints.down('lg')]: {
                    mr: 2
                }
            }}
        >
            <Avatar
                variant="rounded"
                sx={{
                    ...theme.typography.commonAvatar,
                    ...theme.typography.mediumAvatar,
                    transition: 'all .2s ease-in-out',
                    background: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.secondary.light,
                    color: theme.palette.mode === 'dark' ? theme.palette.warning.dark : theme.palette.secondary.dark,
                    '&[aria-controls="menu-list-grow"],&:hover': {
                        background: theme.palette.mode === 'dark' ? theme.palette.warning.dark : theme.palette.secondary.dark,
                        color: theme.palette.mode === 'dark' ? theme.palette.grey[800] : theme.palette.secondary.light
                    }
                }}
                aria-haspopup="true"
                onClick={getGsheetAcc}
                color="inherit"
            >
                <IconRefresh stroke={1.5} size="20px" />
            </Avatar>
        </Box>
    );
};
export default RefreshSection;
