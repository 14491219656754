import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    transEdges: [],
    categoryMapping: false,
    firstLoad: false,
    edges: []
};

export const transition = createSlice({
    name: 'transact',
    initialState,
    reducers: {
        setTransactionEdges: (state, action) => {
            state.transEdges = action.payload;
        },
        handleCategoryMapping: (state, action) => {
            state.categoryMapping = action.payload;
        },
        handleFirstLoadMapping: (state, action) => {
            state.firstLoad = action.payload;
        },
        saveEdges: (state, action) => {
            state.edges = action.payload;
        }
    }
});

// Action creators are generated for each case reducer function
export const { setTransactionEdges, handleCategoryMapping, saveEdges, handleFirstLoadMapping } = transition.actions;

export default transition.reducer;
