import { Route, Routes, createBrowserRouter } from 'react-router-dom';

// routes
import MainRoutes from './MainRoutes';
import LoginRoutes from './LoginRoutes';
import AuthenticationRoutes from './AuthenticationRoutes';
import { useEffect, useState } from 'react';
// import { useSelector } from 'react-redux';
// import TransactionAnalysis from 'views/transactions-data-analysis';
// import PensionAnalysis from 'views/pension-data-analysis';
// import AssetsAnalysis from 'views/assets-analysis';

// ==============================|| ROUTING RENDER ||============================== //

const router = createBrowserRouter([LoginRoutes, AuthenticationRoutes, MainRoutes], {
    basename: process.env.REACT_APP_BASE_NAME
});

export const AllRoutes = () => {
    // let enableRoute = useSelector((state) => state.sankey.activePages);

    const [routesSetting, setRoutesSetting] = useState([LoginRoutes, AuthenticationRoutes, MainRoutes]);

    useEffect(() => {
        // debugger; // eslint-disable-line no-debugger
        let copyroutes = [...routesSetting];
        // console.log('routesSetting', copyroutes[2]);
        // let mainroutes = copyroutes[2];
        // mainroutes?.children?.pop({ path: '/dashboard/transactions/' });
        // copyroutes[2] = { ...mainroutes };
        // console.log('After', copyroutes[2]);
        // //
        // if (enableRoute['Transactions']) {
        //     if (enableRoute['Transactions'] === 'Yes') {
        //         mainroutes?.children?.push({ path: '/transactionsAnalysis', element: <TransactionAnalysis /> });
        //     }
        // }
        // if (enableRoute['Pensions']) {
        //     if (enableRoute['Pensions'] === 'Yes') {
        //         mainroutes?.children?.push({ path: '/pensionAnalysis', element: <PensionAnalysis /> });
        //     }
        // }
        // if (enableRoute['Assets']) {
        //     if (enableRoute['Assets'] === 'Yes') {
        //         mainroutes?.children?.push({ path: '/assetsAnalysis', element: <AssetsAnalysis /> });
        //     }
        // }
        // copyroutes[2] = { ...mainroutes };
        setRoutesSetting([...copyroutes]);
    }, []);
    return (
        <Routes>
            {routesSetting.map((x) => (
                <Route key={x.element} path={x.path} element={x.element}>
                    {x.children.map((y) => (
                        <Route key={y.element} path={y.path} element={y.element} />
                    ))}
                </Route>
            ))}
        </Routes>
    );
};

export default router;
