// material-ui
import { Box, ListItemButton, ListItemIcon, ListItemText, Typography } from '@mui/material';

import useConfig from 'hooks/useConfig';

import { useDispatch, useSelector } from 'store';
// import { IconUser } from '@tabler/icons-react';
import { IconLogout } from '@tabler/icons-react';
import { FormattedMessage } from 'react-intl';
// import useAuth from 'hooks/useAuth';
// import { useNavigate } from 'react-router-dom';

import { setActivePages, setSaveGsheetID, setSaveGsheetIDURL } from 'store/slices/SankeyChart';
import { useAuth } from 'utils/route-guard/OutsetaGuard';

const ProfileSidebar = () => {
    const {
        logout,
      } = useAuth();
    const { drawerOpen } = useSelector((state) => state.menu);

    const { borderRadius } = useConfig();
    // const { logout } = useAuth();
    // const navigate = useNavigate();
    const dispatch = useDispatch();

    // function handleSignoutClick() {
    //     gapi.auth2.getAuthInstance().signOut();

    // }

    const logoutUser = async () => {
        dispatch(setActivePages({}));
        try {
            const response = await fetch(process.env.REACT_APP_SERVER_URL + 'logout');
            if (response.ok) {
                logoutUserGsheetAccess();
            } else {
                console.error('API request failed');
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };
    const logoutUserGsheetAccess = async () => {

        dispatch(setSaveGsheetID(''));
        dispatch(setSaveGsheetIDURL(''));

        // localStorage.removeItem('gSheetID');
        // localStorage.removeItem('gSheeet_url');
        localStorage.removeItem('gToken');
        localStorage.removeItem('userToken');
        
        await logout();
        // try {
        //     const headers = new Headers({
        //         Authorization: localStorage.getItem('gToken')
        //     });
        //     const requestOptions = {
        //         method: 'GET', // or 'GET', 'PUT', etc.
        //         headers: headers
        //     };
        //     const response = await fetch(process.env.REACT_APP_SERVER_URL + 'revokeGsheetAccess', requestOptions);
        //     if (response.ok) {
        //         dispatch(setSaveGsheetID(''));
        //         dispatch(setSaveGsheetIDURL(''));

        //         // localStorage.removeItem('gSheetID');
        //         // localStorage.removeItem('gSheeet_url');
        //         localStorage.removeItem('gToken');
        //         localStorage.removeItem('usertoken');
                
        //         await logout();
        //     } else {
        //         console.error('API request failed');
        //     }
        // } catch (error) {
        //     console.error('Error:', error);
        // }
    };

    const handleLogout = async () => {
        try {
            if(window["Outseta"]){
                logout();
            }
            console.log(logoutUser())
            
        } catch (err) {
            console.error(err);
        }
    };
    return (
        <div>
            {/* <Box sx={{ display: { xs: 'none', lg: 'block' } }}>
                <ListItemButton
                    sx={{ px: '13px', mx: 'auto', ml: '13px', mr: !drawerOpen ? '13px' : '0px', borderRadius: `${borderRadius}px` }}
                    onClick={() => navigate('/settings')}
                >
                    <ListItemIcon>
                        <IconUser stroke={1.5} size="20px" />
                    </ListItemIcon>
                    {drawerOpen && (
                        <ListItemText
                            primary={
                                <Typography variant="body2">
                                    <FormattedMessage id="Profile" />
                                </Typography>
                            }
                        />
                    )}
                </ListItemButton>
            </Box> */}
            <Box sx={{ display: { xs: 'none', lg: 'block' } }}>
                <ListItemButton
                    sx={{ mx: 'auto', ml: '13px', mr: !drawerOpen ? '13px' : '0px', borderRadius: `${borderRadius}px` }}
                    onClick={handleLogout}
                >
                    <ListItemIcon>
                        <IconLogout stroke={1.5} size="20px" />
                    </ListItemIcon>
                    {drawerOpen && (
                        <ListItemText
                            primary={
                                <Typography variant="body2">
                                    <FormattedMessage id="logout" />
                                </Typography>
                            }
                        />
                    )}
                     {/* <a href='https://intelligent-money-hub.outseta.com/profile#/#o-logout-link'>Logout</a>    */}
                </ListItemButton>
            </Box>
        </div>
    );
};

export default ProfileSidebar;
