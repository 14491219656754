// material-ui
import { Button, Grid, Stack, TextField, Typography } from '@mui/material';

// project imports
import Avatar from 'ui-component/extended/Avatar';
import { gridSpacing } from 'store/constant';

// assets
import ErrorTwoToneIcon from '@mui/icons-material/ErrorTwoTone';
import AnimateButton from 'ui-component/extended/AnimateButton';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { showenqueSnackbar } from 'Helpers/gsheetHelpers';

// ==============================|| PROFILE 2 - USER PROFILE ||============================== //

const UserProfile = () => {
    const [ID, setID] = useState('');
    const [name, setname] = useState('');
    const [email, setEmail] = useState('');
    const [img, setimg] = useState('');
    
    useEffect(() => {
        getUserProfile();
    }, []);


    const getUserProfile = () => {

        axios({
            method: 'get',
            url: process.env.REACT_APP_SERVER_URL + 'api/getUserProfile',
            headers: {
              Authorization: `Bearer ${localStorage.getItem('userToken')}`,
            }
          })
            .then(function (response) {
              console.log(response)
              setname(response.data.name)
              setEmail(response.data.email)
              setimg(response.data.img)
              setID(response.data._id);
            
            }).catch(function(err){
              console.log(err);
            });
        
    }

    const updateUserProfile = () => {
        let data = {
            id:ID,
            name:name,
            email:email,
        }
        axios({
            method: 'post',
            url: process.env.REACT_APP_SERVER_URL + 'api/save_profile',
            data:data,
            headers: {
              Authorization: `Bearer ${localStorage.getItem('userToken')}`,
            }
          })
            .then(function (response) {
              console.log(response)
              showenqueSnackbar(response.data.message,'success')
              getUserProfile()
            }).catch(function(err){
              console.log(err);
            });
    } 
    
    return (
    <Grid container spacing={gridSpacing}>
        <Grid item xs={12}>
            <Grid container spacing={2} alignItems="center">
                <Grid item>
                    {img ? <img src={img} alt='user '  style={{ height: 80, width: 80 }} /> : <Avatar  color="default" alt="User 1" sx={{ height: 80, width: 80 }} />}
                    
                </Grid>
                <Grid item sm zeroMinWidth>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <Stack direction="row" spacing={2} alignItems="center">
                                <input accept="image/*" style={{ display: 'none' }} id="contained-button-file" multiple type="file" />
                            </Stack>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="caption">
                                <ErrorTwoToneIcon sx={{ height: 16, width: 16, mr: 1, verticalAlign: 'text-bottom' }} />
                                Image size Limit should be 125kb Max.
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
        <Grid item xs={12} sm={6}>
            <TextField  fullWidth label="Name" value={name} onChange={e => setname(e.target.value)} />
        </Grid>
        <Grid item xs={12} sm={6}>
            <TextField fullWidth label="Email Address" onChange={e => setEmail(e.target.email)} value={email} />
        </Grid>

        <Grid container justifyContent="space-between" style={{ marginTop: '10px' }} spacing={0}>
            <Grid item></Grid>
            <Grid item>
                <AnimateButton>
                    <Button onClick={updateUserProfile} variant="contained" style={{ marginTop: '5px' }} size="large">
                        Save
                    </Button>
                </AnimateButton>
            </Grid>
        </Grid>
    </Grid>
)};

export default UserProfile;
