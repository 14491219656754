// project imports
import MainCard from 'ui-component/cards/MainCard';
import ComingSoon from 'views/coming-soon';

const ChatPage = () => {
    return (
        <MainCard title="Chat">
            <ComingSoon />
        </MainCard>
    );
};

export default ChatPage;
