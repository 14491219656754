import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    enableRoutes: {
        transaction: true,
        assets: true
    }
};

export const settings = createSlice({
    name: 'settings',
    initialState,
    reducers: {
        handleEnableRouting: (state, action) => {
            state.enableRoutes.transaction = action.payload.transaction;
            state.enableRoutes.assets = action.payload.assets;
        }
    }
});

// Action creators are generated for each case reducer function
export const { handleEnableRouting } = settings.actions;

export default settings.reducer;
