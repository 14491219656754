// third-party
import { combineReducers } from 'redux';

// project imports
import snackbarReducer from './slices/snackbar';
import menuReducer from './slices/menu';
import sankeyChart from './slices/SankeyChart';
import transaction from './slices/transaction';
import settingsReducer from './slices/settings';
import userProfileReducer from "./slices/UserProfile";

// ==============================|| COMBINE REDUCER ||============================== //

const reducer = combineReducers({
    snackbar: snackbarReducer,
    sankey: sankeyChart,
    menu: menuReducer,
    transact: transaction,
    settings: settingsReducer,
    userProfile:userProfileReducer
});

export default reducer;
