import PropTypes from 'prop-types';

// material-ui
import { Button, Grid, MenuItem, Stack, TextField } from '@mui/material';

// project imports
import AnimateButton from 'ui-component/extended/AnimateButton';

// third-party
import { useFormik } from 'formik';
import * as yup from 'yup';
import { countryList, currencyList, dateFormat, days, months } from 'utils/config';

const validationSchema = yup.object({
    country: yup.string().required('Country is required'),
    currency: yup.string().required('Currency is required'),
    dateFormat: yup.string().required('Date Format is required'),
    startOfMonth: yup.string().required('Start Of Month is required'),
    startOfFinancialYearMonth: yup.string().required('Start Of Financial Year Month is required'),
    startOfFinancialYearDay: yup.string().required('Start Of Financial Year Day is required')
});

// ==============================|| FORM WIZARD - VALIDATION ||============================== //

export default function UserSettingsForm({ UserSettings, setUserSettings, handleNext,  setErrorIndex }) {
    const formik = useFormik({
        initialValues: {
            country: UserSettings.country,
            currency: UserSettings.currency,
            dateFormat: UserSettings.dateFormat,
            startOfMonth: UserSettings.startOfMonth,
            startOfFinancialYearMonth: UserSettings.startOfFinancialYearMonth,
            startOfFinancialYearDay: UserSettings.startOfFinancialYearDay
        },
        validationSchema,
        onSubmit: (values) => {
            setUserSettings({
                country: values.country,
                currency: values.currency,
                dateFormat: values.dateFormat,
                startOfMonth: values.startOfMonth,
                startOfFinancialYearMonth: values.startOfFinancialYearMonth,
                startOfFinancialYearDay: values.startOfFinancialYearDay
            });
            handleNext();
        }
    });

    return (
        <>
            <form onSubmit={formik.handleSubmit}>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={6}>
                            <TextField
                                id="standard-select-country"
                                select
                                name="country"
                                label="Select Country"
                                fullWidth
                                value={formik.values.country}
                                onChange={formik.handleChange}
                                error={formik.touched.country && Boolean(formik.errors.country)}
                                helperText={formik.touched.country && formik.errors.country}
                            >
                                {countryList.map((option) => (
                                    <MenuItem key={option.value} value={option.value}>
                                        {option.label}
                                    </MenuItem>
                                ))}
                            </TextField>
                    </Grid>
                    <Grid item xs={12} md={6}>
                    <TextField
                                id="standard-select-currency"
                                select
                                name="currency"
                                label="Select Currency"
                                fullWidth
                                value={formik.values.currency}
                                onChange={formik.handleChange}
                                error={formik.touched.currency && Boolean(formik.errors.currency)}
                                helperText={formik.touched.currency && formik.errors.currency}
                            >
                                {currencyList.map((option, index) => (
                                    <MenuItem key={option.value + index} value={option.value}>
                                        {`${option.value}`}
                                    </MenuItem>
                                ))}
                            </TextField>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                  
                            <TextField
                                select
                                label="Date Format"
                                name="dateFormat"
                                fullWidth
                                value={formik.values.dateFormat}
                                onChange={formik.handleChange}
                                error={formik.touched.dateFormat && Boolean(formik.errors.dateFormat)}
                                helperText={formik.touched.dateFormat && formik.errors.dateFormat}
                            >
                                {dateFormat.map((option) => (
                                    <MenuItem key={option.value} value={option.value}>
                                        {option.label}
                                    </MenuItem>
                                ))}
                            </TextField>
                      
                </Grid>
                <Grid item xs={12} sm={12}>
                  
                            <TextField
                                select
                                label="Start of Month"
                                fullWidth
                                name="startOfMonth"
                                value={formik.values.startOfMonth}
                                onChange={formik.handleChange}
                                error={formik.touched.startOfMonth && Boolean(formik.errors.startOfMonth)}
                                helperText={formik.touched.startOfMonth && formik.errors.startOfMonth}
                            >
                                {days.map((option) => (
                                    <MenuItem key={option.value} value={option.value}>
                                        {option.label}
                                    </MenuItem>
                                ))}
                            </TextField>
                     
                </Grid>
                <Grid item xs={12} sm={6}>
                  
                            <TextField
                                select
                                label="Start of Financial year Month"
                                fullWidth
                                name="startOfFinancialYearMonth" 
                                value={formik.values.startOfFinancialYearMonth}
                                onChange={formik.handleChange}
                                error={formik.touched.startOfFinancialYearMonth && Boolean(formik.errors.startOfFinancialYearMonth)}
                                helperText={formik.touched.startOfFinancialYearMonth && formik.errors.startOfFinancialYearMonth}
                            >
                                {months.map((option) => (
                                    <MenuItem key={option.value} value={option.value}>
                                        {option.label}
                                    </MenuItem>
                                ))}
                            </TextField>
                    
                </Grid>
                <Grid item xs={12} sm={6}>
                  
                            <TextField
                                select
                                label="Start of Financial year Day"
                                fullWidth
                                name="startOfFinancialYearDay"
                                value={formik.values.startOfFinancialYearDay}
                                onChange={formik.handleChange}
                                error={formik.touched.startOfFinancialYearDay && Boolean(formik.errors.startOfFinancialYearDay)}
                                helperText={formik.touched.startOfFinancialYearDay && formik.errors.startOfFinancialYearDay}
                            >
                                {days.map((option) => (
                                    <MenuItem key={option.value} value={option.value}>
                                        {option.label}
                                    </MenuItem>
                                ))}
                            </TextField>
                     
                </Grid>
                    <Grid item xs={12}>
                        <Stack direction="row" justifyContent="space-between">
                            <div></div>
                            <AnimateButton>
                                <Button variant="contained" type="submit" sx={{ my: 3, ml: 1 }} onClick={() => setErrorIndex(1)}>
                                    Next
                                </Button>
                            </AnimateButton>
                        </Stack>
                    </Grid>
                </Grid>
            </form>
        </>
    );
}

UserSettingsForm.propTypes = {
    paymentData: PropTypes.object,
    setPaymentData: PropTypes.func,
    handleNext: PropTypes.func,
    handleBack: PropTypes.func,
    setErrorIndex: PropTypes.func
};
