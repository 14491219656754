import axios from 'axios';
import { ALLPAGES } from 'config';
import { enqueueSnackbar } from 'notistack';

const getSheet = async (SheetId, sheetName) => {
    try {
        const headers = {
            Authorization: localStorage.getItem('gToken')
        };
        // Make a POST request to your server
        const response = await axios.post(
            process.env.REACT_APP_SERVER_URL + 'read-google-sheet',
            {
                sheetID: SheetId,
                sheetName: sheetName
            },
            {
                headers: headers
            }
        );
        // Update the state with the response data
        return response.data.data;
    } catch (err) {
        // Handle errors
        console.log(err);
        return 'error';
    }
};

export async function getSheetData(SheetId, sheetName, fetchPages) {
    let result = await getSheet(SheetId, sheetName);
    return new Promise((resolve) => {
        if (result === 'error') {
            return;
        }
        var range = result;
        let show = {};
        if (fetchPages) {
            ALLPAGES.map((x) => Object.assign(show, findDropdownValuesForPage(x, range.values)));
        }
        var data = [];
        if (range.values.length > 0) {
            var key = range.values[0];
            for (let i = 1; i < range.values.length; i++) {
                var row = range.values[i];
                const newObj = {};
                key.forEach((v, i) => {
                    newObj[v] = row[i];
                });
                data.push(newObj);
            }

            if (fetchPages) {
                resolve({ data, pages: show });
            } else {
                resolve({ data });
            }
        } else {
            showenqueSnackbar('No Data Found.', 'error');
        }
    });
}

// export function getSheetData(SheetId, sheetName) {
//     return new Promise((resolve, reject) => {
//         let result = getSheet();
//         gapi?.client?.sheets?.spreadsheets?.values
//             ?.get({
//                 spreadsheetId: SheetId,
//                 range: sheetName + '!A1:AA100000',
//                 includeGridData: true
//             })
//             .then(
//                 function (response) {
//                     var range = response.result;
//                     console.log(range);
//                     let show = {};
//                     ALLPAGES.map((x) => Object.assign(show, findDropdownValuesForPage(x, range.values)));
//                     var data = [];
//                     if (range.values.length > 0) {
//                         var key = range.values[0];
//                         for (let i = 1; i < range.values.length; i++) {
//                             var row = range.values[i];
//                             const newObj = {};
//                             key.forEach((v, i) => {
//                                 newObj[v] = row[i];
//                             });
//                             data.push(newObj);
//                         }
//                         resolve({ data, pages: show });
//                     } else {
//                         showenqueSnackbar('No Data Found.', 'error');
//                     }
//                 },
//                 function (response) {
//                     showenqueSnackbar(response.result.error.message, 'error');
//                     reject(response.result.error.message);
//                 }
//             );
//     });
// }

export const showenqueSnackbar = (message, varient) => {
    enqueueSnackbar(message, {
        preventDuplicate: true,
        iconVariant: 'useemojis',
        anchorOrigin: { horizontal: 'right', vertical: 'top' },
        variant: varient
    });
};

function findDropdownValuesForPage(pageName, data) {
    // debugger; // eslint-disable-line no-debugger
    const dropdownValues = {};
    for (let i = 0; i < data.length; i++) {
        const row = data[i];

        // Check if the first cell in the row matches the specified pageName
        if (row.length > 0 && row[0].toLowerCase() === pageName.toLowerCase()) {
            // Find the index of "Enable Page" in the same row
            const enablePageIndex = row.indexOf('Enable Page');

            // If "Enable Page" is found in the row
            if (enablePageIndex !== -1) {
                // Add the dropdown values to the array
                let checkinNExt = data[i + 1];
                let val = checkinNExt[enablePageIndex];
                if (val) {
                    Object.assign(dropdownValues, { [pageName]: val });
                    //   dropdownValues.push({[pageName]:val});
                }
            }
        }
    }
    return dropdownValues;
}

const getSheetSummit = async (SheetId, sheetName) => {
    try {
        const headers = {
            Authorization: localStorage.getItem('gToken')
        };
        // Make a POST request to your server
        const response = await axios.post(
            process.env.REACT_APP_SERVER_URL + 'read-google-sheet',
            {
                sheetID: SheetId,
                sheetName: sheetName
            },
            {
                headers: headers
            }
        );
        // Update the state with the response data
        return response.data.data;
    } catch (err) {
        // Handle errors
        console.log(err);
        console.log(err.response?.data);
        const errorRes = {
            errMessage: err.response?.data.error || 'Something went wrong',
            type: 'error'
        };
        return errorRes;
    }
};

const getSheetsDataWithIDS = async (SheetId, sheetName) => {
   
    try {
        const headers = {
            Authorization: localStorage.getItem('gToken'),
            userSession: `Bearer ${localStorage.getItem('userToken')}`,
            mappingHeaders: localStorage.getItem('mappingHeaders'),
        };
        // Make a POST request to your server
        const response = await axios.post(
            process.env.REACT_APP_SERVER_URL + 'dataSheetIDS',
            {
                sheetID: SheetId,
                sheetName: sheetName
            },
            {
                headers: headers
            }
        );
        // Update the state with the response data
        return response.data.data;
    } catch (err) {
        // Handle errors
        console.log(err);
        console.log(err.response?.data);
        const errorRes = {
            errMessage: err.response?.data.error || 'Something went wrong',
            type: 'error'
        };
        return errorRes;
    }
};

export async function getSheetDataSummit(SheetId, sheetName) {
    let result = await getSheetSummit(SheetId, sheetName);
    return new Promise((resolve) => {
        if (result?.type === 'error') {
            resolve({
                data: {
                    error: result?.errMessage || null
                }
            });
            return null;
        }
        var range = result;
        var data = [];
        if (range.values.length > 0) {
            var key = range.values[0];
            for (let i = 1; i < range.values.length; i++) {
                var row = range.values[i];
                const newObj = {};
                key.forEach((v, i) => {
                    newObj[v] = row[i];
                });
                data.push(newObj);
            }
            resolve({ data });
        } else {
            return null;
        }
    });
}

export async function getSheetData_ids(SheetId, sheetName) {
    let result = await getSheetsDataWithIDS(SheetId, sheetName);
    return new Promise((resolve) => {
        if (result?.type === 'error') {
            resolve({
                data: {
                    error: result?.errMessage || null
                }
            });
            return null;
        }
        var data = result;
        resolve({ data });
    });
}

export const getDataStatus = async (data) => {
    try {
        let datas = {
            category: data.label,
            nodeStatus: data.nodeStatus
        };
        const headers = {
            Authorization: localStorage.getItem('gToken'),
            userSession: `Bearer ${localStorage.getItem('userToken')}`
        };
        // Make a POST request to your server
        const response = await axios.post(process.env.REACT_APP_SERVER_URL + 'UpdateNodeStatus', datas, {
            headers: headers
        });
        // Update the state with the response data
        return response.data.data;
    } catch (err) {
        // Handle errors
        console.log(err);
        console.log(err.response?.data);
        const errorRes = {
            errMessage: err.response?.data.error || 'Something went wrong',
            type: 'error'
        };
        return errorRes;
    }
};
