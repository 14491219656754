import { useTheme } from '@emotion/react';
import { Box } from '@mui/system';
import { IconZoomMoney } from '@tabler/icons-react';
import { Link as RouterLink } from 'react-router-dom';

const TransactionSection = () => {
    const theme = useTheme();
    return (
        <Box
            sx={{
                ml: 2,
                [theme.breakpoints.down('md')]: {
                    ml: 1
                }
            }}
        >
            <RouterLink
                component={RouterLink}
                to={'/dashboard/cash-flow/'}
                aria-label="Trans"
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '5px',
                    color: theme.palette.text.primary,
                    textDecoration: 'none',
                    fontWeight: '500',
                    fontSize: '16px'
                }}
            >
                <IconZoomMoney size={'40px'} /> Cash Flow
            </RouterLink>
        </Box>
    );
};
export default TransactionSection;
