// routing
import { AllRoutes } from 'routes';
// project imports
import Locales from 'ui-component/Locales';
import NavigationScroll from 'layout/NavigationScroll';
import Snackbar from 'ui-component/extended/Snackbar';
import Notistack from 'ui-component/third-party/Notistack';

import ThemeCustomization from 'themes';

// auth provider
import { JWTProvider as AuthProvider } from 'contexts/JWTContext';
import { useEffect } from 'react';
import { getSheetData } from 'Helpers/gsheetHelpers';
import { setActivePages, setSaveGsheetID, setSaveGsheetIDURL } from 'store/slices/SankeyChart';
import { useDispatch } from 'store';

const App = () => {
    return (
        <ThemeCustomization>
            <Locales>
                <NavigationScroll>
                    <AuthProvider>
                        <>
                            <GOOGLEAuth />
                            <Notistack>
                                <AllRoutes />
                                <Snackbar />
                            </Notistack>
                        </>
                    </AuthProvider>
                </NavigationScroll>
            </Locales>
        </ThemeCustomization>
    );
};

export default App;

const GOOGLEAuth = () => {
    const dispatch = useDispatch();
    useEffect(() => {
        LoadConfigFromGSHEET();
    }, []);

    const LoadConfigFromGSHEET = async () => {
        let ID = localStorage.getItem('gSheetID');
        let sheet_url = localStorage.getItem('gSheet_url');
        if (ID) {
            dispatch(setSaveGsheetID(ID));
            dispatch(setSaveGsheetIDURL(sheet_url));
            let gsheetData = await getSheetData(ID, 'KFC', true);
            if (gsheetData.data && gsheetData.pages) {
                dispatch(setActivePages(gsheetData.pages));
            }
        } else {
            // showenqueSnackbar("Please add GSHEET URL FIRST" , 'error')
        }
    };

    return <></>;
};
