import React, { useEffect, useState } from 'react';

// material-ui
import { Button, Grid, MenuItem, TextField } from '@mui/material';

// project imports
import { gridSpacing } from 'store/constant';
import { countryList, currencyList, dateFormat, days, months } from 'utils/config';

import AnimateButton from 'ui-component/extended/AnimateButton';
import { useForm, Controller } from 'react-hook-form';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { setUserSettings } from 'store/slices/UserProfile';
import { showenqueSnackbar } from 'Helpers/gsheetHelpers';

const Settings = () => {
    const dispatch = useDispatch();
    const [ID, setID] = useState('');
    const { handleSubmit, control, setValue } = useForm();
    const onSubmit = (data) => {
        updateUserProfileSettings(data);
    };

    const settingsFromStore = useSelector((state) => state.userProfile.settings);

    useEffect(() => {
        getUserProfileSettings();
    }, []);

    useEffect(() => {
        console.log(settingsFromStore);
    }, [settingsFromStore]);

    const getUserProfileSettings = () => {
        axios({
            method: 'get',
            url: process.env.REACT_APP_SERVER_URL + 'api/getUserProfileSettings',
            headers: {
                // Authorization: `Bearer ${localStorage.getItem('userToken')}`
                Authorization: `Bearer ${localStorage.getItem('OaccessToken')}`
            }
        })
            .then(function (response) {
                console.log(response);
                if (response) {
                    let settings = {
                        country: response.data.country,
                        currency: response.data.currency,
                        dateFormat: response.data.dateFormat,
                        startOfMonth: response.data.startOfMonth,
                        startOfFinancialYearMonth: response.data.startOfFinancialYearMonth,
                        startOfFinancialYearDay: response.data.startOfFinancialYearDay
                    };
                    dispatch(setUserSettings(settings));
                    setID(response.data.userId);
                    setValue('country', response.data.country);
                    setValue('currency', response.data.currency);
                    setValue('dateFormat', response.data.dateFormat);
                    setValue('startOfMonth', response.data.startOfMonth);
                    setValue('startOfFinancialYearMonth', response.data.startOfFinancialYearMonth);
                    setValue('startOfFinancialYearDay', response.data.startOfFinancialYearDay);
                }
            })
            .catch(function (err) {
                console.log(err);
            });
    };

    const updateUserProfileSettings = (data) => {
        let datas = {
            id: ID,
            ...data
        };

        axios({
            method: 'post',
            url: process.env.REACT_APP_SERVER_URL + 'api/save_profile_settings',
            data: datas,
            headers: {
                // Authorization: `Bearer ${localStorage.getItem('userToken')}`
                Authorization: `Bearer ${localStorage.getItem('OaccessToken')}`
            }
        })
            .then(function (response) {
                console.log(response);
                showenqueSnackbar(response.data.message, 'success');
                getUserProfileSettings();
            })
            .catch(function (err) {
                console.log(err);
            });
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={gridSpacing}>
                <Grid item xs={12} sm={12}>
                    <Controller
                        name="country" // Field name
                        control={control}
                        defaultValue="" // Default value
                        render={({ field }) => (
                            <TextField id="standard-select-country" select label="Select Country" fullWidth {...field}>
                                {countryList.map((option) => (
                                    <MenuItem key={option.value} value={option.value}>
                                        {option.label}
                                    </MenuItem>
                                ))}
                            </TextField>
                        )}
                    />
                </Grid>
                <Grid item xs={12} sm={12}>
                    <Controller
                        name="currency" // Field name
                        control={control}
                        defaultValue="" // Default value
                        render={({ field }) => (
                            <TextField id="standard-select-currency" select label="Select Currency" fullWidth {...field}>
                                {currencyList.map((option, index) => (
                                    <MenuItem key={option.value + index} value={option.value}>
                                        {`${option.value}`}
                                    </MenuItem>
                                ))}
                            </TextField>
                        )}
                    />
                </Grid>
                <Grid item xs={12} sm={12}>
                    <Controller
                        name="dateFormat" // Field name
                        control={control}
                        defaultValue="" // Default value
                        render={({ field }) => (
                            <TextField select label="Date Format" {...field} fullWidth>
                                {dateFormat.map((option) => (
                                    <MenuItem key={option.value} value={option.value}>
                                        {option.label}
                                    </MenuItem>
                                ))}
                            </TextField>
                        )}
                    />
                </Grid>
                <Grid item xs={12} sm={12}>
                    <Controller
                        name="startOfMonth" // Field name
                        control={control}
                        defaultValue="" // Default value
                        render={({ field }) => (
                            <TextField select label="Start of Month" fullWidth {...field}>
                                {days.map((option) => (
                                    <MenuItem key={option.value} value={option.value}>
                                        {option.label}
                                    </MenuItem>
                                ))}
                            </TextField>
                        )}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Controller
                        name="startOfFinancialYearMonth" // Field name
                        control={control}
                        defaultValue="" // Default value
                        render={({ field }) => (
                            <TextField select label="Start of Financial year Month" fullWidth {...field}>
                                {months.map((option) => (
                                    <MenuItem key={option.value} value={option.value}>
                                        {option.label}
                                    </MenuItem>
                                ))}
                            </TextField>
                        )}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Controller
                        name="startOfFinancialYearDay" // Field name
                        control={control}
                        defaultValue="" // Default value
                        render={({ field }) => (
                            <TextField select label="Start of Financial year Day" fullWidth {...field}>
                                {days.map((option) => (
                                    <MenuItem key={option.value} value={option.value}>
                                        {option.label}
                                    </MenuItem>
                                ))}
                            </TextField>
                        )}
                    />
                </Grid>

                <Grid container justifyContent="space-between" spacing={0}>
                    <Grid item></Grid>
                    <Grid item>
                        <AnimateButton>
                            <Button type="submit" variant="contained" style={{ marginTop: '5px' }} size="large">
                                Save
                            </Button>
                        </AnimateButton>
                    </Grid>
                </Grid>
            </Grid>
        </form>
    );
};

export default Settings;
