import { createSlice } from '@reduxjs/toolkit'



const initialState = {
    savedGSheetID: '',
    savedGSheetURL:'',
    activePages:{},
    transactionsList: []
}

export const sankeyChart = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setSaveGsheetID: (state, action) => {
      state.savedGSheetID = action.payload
    },
    setSaveGsheetIDURL: (state, action) => {
      state.savedGSheetURL = action.payload
    },
    setActivePages: (state, action) => {
      state.activePages = action.payload
    },
    setTransactionsList: (state,action) => {
      state.transactionsList = action.payload
    }
  },
})

// Action creators are generated for each case reducer function
export const { setSaveGsheetID,setActivePages,setSaveGsheetIDURL , setTransactionsList } = sankeyChart.actions

export default sankeyChart.reducer