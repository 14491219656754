import { useTheme } from '@emotion/react';
import { Avatar, ButtonBase, Chip, ListItemButton, ListItemIcon, ListItemText, Typography, useMediaQuery } from '@mui/material';
import { showenqueSnackbar } from 'Helpers/gsheetHelpers';
import useConfig from 'hooks/useConfig';
import { useDispatch, useSelector } from 'react-redux';
import { activeID, activeItem, openDrawer } from 'store/slices/menu';

const GSheetCustomRoute = ({ item, level, itemIcon, parentId }) => {
    const theme = useTheme();
    const dispatch = useDispatch();
    const matchesSM = useMediaQuery(theme.breakpoints.down('lg'));
    const { borderRadius } = useConfig();
    const { selectedItem, drawerOpen } = useSelector((state) => state.menu);
    const isSelected = selectedItem.findIndex((id) => id === item.id) > -1;
    const textColor = theme.palette.mode === 'dark' ? 'grey.400' : 'text.primary';
    const iconSelectedColor = theme.palette.mode === 'dark' && drawerOpen ? 'text.primary' : 'secondary.main';
    const getGsheetAcc = async () => {
        try {
            const response = await fetch(process.env.REACT_APP_SERVER_URL + 'auth/google/sheet');
            if (response.ok) {
                const data = await response.json();
                // setConsentUrl(data.consentUrl);

                // Open the consent URL in a new window
                window.open(data.authorizationUrl, '_blank', 'location=yes,height=570,width=520,scrollbars=yes,status=yes');
                // Listen for messages from the popup window
                window.addEventListener('message', (event) => {
                    // Check if the message is from the consent window and contains authentication success information
                    if (event.data && event.data.success) {
                        if (event.data.token) {
                            localStorage.setItem('gToken', JSON.stringify(event.data.token));
                            localStorage.setItem('userToken',event.data.userToken);
                        }
                        // Handle the authentication success here
                        showenqueSnackbar('Gsheet Access Granted! please Add Gsheet URL', 'success');

                        // You can update your state or perform other actions here
                    }
                });
            } else {
                console.error('API request failed');
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };
    const itemHandler = (id) => {
        getGsheetAcc();
        dispatch(activeItem([id]));
        if (matchesSM) dispatch(openDrawer(false));
        dispatch(activeID(parentId));
    };

    return (
        <ListItemButton
            disableRipple={!drawerOpen}
            sx={{
                zIndex: 1201,
                borderRadius: `${borderRadius}px`,
                mb: 0.5,
                pl: drawerOpen ? `${level * 24}px` : 1.25,
                ...(drawerOpen &&
                    level === 1 &&
                    theme.palette.mode !== 'dark' && {
                        '&:hover': {
                            background: theme.palette.secondary.light
                        },
                        '&.Mui-selected': {
                            background: theme.palette.secondary.light,
                            color: iconSelectedColor,
                            '&:hover': {
                                color: iconSelectedColor,
                                background: theme.palette.secondary.light
                            }
                        }
                    }),
                ...((!drawerOpen || level !== 1) && {
                    py: level === 1 ? 0 : 1,
                    '&:hover': {
                        bgcolor: 'transparent'
                    },
                    '&.Mui-selected': {
                        '&:hover': {
                            bgcolor: 'transparent'
                        },
                        bgcolor: 'transparent'
                    }
                })
            }}
            selected={isSelected}
            onClick={() => itemHandler(item.id)}
        >
            <ButtonBase aria-label="theme-icon" sx={{ borderRadius: `${borderRadius}px` }} disableRipple={drawerOpen}>
                <ListItemIcon
                    sx={{
                        minWidth: level === 1 ? 36 : 18,
                        color: isSelected ? iconSelectedColor : textColor,
                        ...(!drawerOpen &&
                            level === 1 && {
                                borderRadius: `${borderRadius}px`,
                                width: 46,
                                height: 46,
                                alignItems: 'center',
                                justifyContent: 'center',
                                '&:hover': {
                                    bgcolor: theme.palette.mode === 'dark' ? theme.palette.secondary.main + 25 : 'secondary.light'
                                },
                                ...(isSelected && {
                                    bgcolor: theme.palette.mode === 'dark' ? theme.palette.secondary.main + 25 : 'secondary.light',
                                    '&:hover': {
                                        bgcolor: theme.palette.mode === 'dark' ? theme.palette.secondary.main + 30 : 'secondary.light'
                                    }
                                })
                            })
                    }}
                >
                    {itemIcon}
                </ListItemIcon>
            </ButtonBase>

            {(drawerOpen || (!drawerOpen && level !== 1)) && (
                <ListItemText
                    primary={
                        <Typography variant={isSelected ? 'h5' : 'body1'} color="inherit">
                            {item.title}
                        </Typography>
                    }
                    secondary={
                        item.caption && (
                            <Typography variant="caption" sx={{ ...theme.typography.subMenuCaption }} display="block" gutterBottom>
                                {item.caption}
                            </Typography>
                        )
                    }
                />
            )}

            {drawerOpen && item.chip && (
                <Chip
                    color={item.chip.color}
                    variant={item.chip.variant}
                    size={item.chip.size}
                    label={item.chip.label}
                    avatar={item.chip.avatar && <Avatar>{item.chip.avatar}</Avatar>}
                />
            )}
        </ListItemButton>
    );
};

export default GSheetCustomRoute;
