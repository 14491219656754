// third-party
import { FormattedMessage } from 'react-intl';

// assets
import {
    IconDashboard,
    IconDeviceAnalytics,
    IconHomeSignal,
    IconZoomMoney,
    IconStairsUp,
    IconStairsDown,
    IconCurrencyBitcoin,
    IconBrandCakephp,
    IconBrandMastercard,
    IconHomeDollar,
    IconBolt,
    IconFlame,
    IconRipple,
    IconListDetails,
    IconMoodDollar
} from '@tabler/icons-react';

const icons = {
    IconDashboard,
    IconDeviceAnalytics,
    IconHomeSignal,
    IconZoomMoney,
    IconStairsUp,
    IconStairsDown,
    IconCurrencyBitcoin,
    IconBrandCakephp,
    IconBrandMastercard,
    IconHomeDollar,
    IconBolt,
    IconFlame,
    IconRipple,
    IconListDetails,
    IconMoodDollar
};

// ==============================|| MENU ITEMS - DASHBOARD ||============================== //

const dashboard = {
    id: 'dashboard',
    title: <FormattedMessage id="dashboards" />,
    icon: icons.IconHomeSignal,
    type: 'group',
    children: [
        // {
        //     id: 'Home',
        //     title: <FormattedMessage id="Home" />,
        //     type: 'item',
        //     url: '/dashboard',
        //     icon: icons.IconHomeSignal
        // },
        // {
        //     id: 'cash-flow',
        //     title: <FormattedMessage id="Cash Flow" />,
        //     type: 'item',
        //     url: '/dashboard',
        //     icon: icons.IconHomeSignal
        // },
        {
            id: 'cash-flow',
            title: <FormattedMessage id="Cash Flow" />,
            type: 'item',
            url: '/dashboard/cash-flow/',
            icon: icons.IconZoomMoney
        }
        // {
        //     id: 'transactions',
        //     title: <FormattedMessage id="Transactions" />,
        //     type: 'item',
        //     url: '/dashboard/transactions/',
        //     icon: icons.IconZoomMoney
        // }
        // {
        //     id: 'assets',
        //     title: <FormattedMessage id="Assets" />,
        //     type: 'item',
        //     url: '/dashboard/assets/',
        //     icon: icons.IconStairsUp
        // },
        // {
        //     id: 'liabilities',
        //     title: <FormattedMessage id="Liabilities" />,
        //     type: 'item',
        //     url: '/dashboard/liabilities/',
        //     icon: icons.IconStairsDown
        // },
        // {
        //     id: 'crypto',
        //     title: <FormattedMessage id="Crypto" />,
        //     type: 'item',
        //     url: '/dashboard/crypto/',
        //     icon: icons.IconCurrencyBitcoin
        // },
        // {
        //     id: 'pensions',
        //     title: <FormattedMessage id="Pensions" />,
        //     type: 'item',
        //     url: '/dashboard/pensions/',
        //     icon: icons.IconBrandCakephp
        // },
        // {
        //     id: 'loans',
        //     title: <FormattedMessage id="Loans" />,
        //     type: 'item',
        //     url: '/dashboard/loans/',
        //     icon: icons.IconBrandMastercard
        // },
        // {
        //     id: 'mortgages',
        //     title: <FormattedMessage id="Mortgages" />,
        //     type: 'item',
        //     url: '/dashboard/mortgages/',
        //     icon: icons.IconHomeDollar
        // },
        // {
        //     id: 'electricity',
        //     title: <FormattedMessage id="Electricity" />,
        //     type: 'item',
        //     url: '/dashboard/electricity/',
        //     icon: icons.IconBolt
        // },
        // {
        //     id: 'gas',
        //     title: <FormattedMessage id="Gas" />,
        //     type: 'item',
        //     url: '/dashboard/gas/',
        //     icon: icons.IconFlame
        // },
        // {
        //     id: 'water',
        //     title: <FormattedMessage id="Water" />,
        //     type: 'item',
        //     url: '/dashboard/water/',
        //     icon: icons.IconRipple
        // },
        // {
        //     id: 'contracts-and-Subscriptions',
        //     title: <FormattedMessage id="Contracts and Subscriptions" />,
        //     type: 'item',
        //     url: '/dashboard/contracts-and-Subscriptions/',
        //     icon: icons.IconListDetails
        // },
        // {
        //     id: 'retirement',
        //     title: <FormattedMessage id="Retirement" />,
        //     type: 'item',
        //     url: '/dashboard/retirement/',
        //     icon: icons.IconMoodDollar
        // }
    ]
};

export default dashboard;
