import { createSlice } from '@reduxjs/toolkit'



const initialState = {
    settings:{}
}

export const userProfile = createSlice({
  name: 'userProfile',
  initialState,
  reducers: {
    setUserSettings: (state, action) => {
      state.settings = action.payload
    },
    
  },
})

// Action creators are generated for each case reducer function
export const {setUserSettings } = userProfile.actions

export default userProfile.reducer