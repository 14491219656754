// material-ui
// import { Button, Grid, Link, Switch, TextField, Typography } from '@mui/material';
import { Button, Grid, Link,  TextField, Typography } from '@mui/material';
import { setActivePages, setSaveGsheetID, setSaveGsheetIDURL } from 'store/slices/SankeyChart';
import { getSheetData, showenqueSnackbar } from 'Helpers/gsheetHelpers';
// project imports
import MainCard from 'ui-component/cards/MainCard';
import SubCard from 'ui-component/cards/SubCard';
import { gridSpacing } from 'store/constant';
import AnimateButton from 'ui-component/extended/AnimateButton';
import { Box } from '@mui/system';
// import { handleEnableRouting } from 'store/slices/settings';
// import { useDispatch, useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { IconFileSpreadsheet } from '@tabler/icons-react';
import { useEffect, useState } from 'react';

// ==============================|| TEXTFIELD PAGE ||============================== //

const GSheetDataMapping = () => {
    const dispatch = useDispatch();
    const [sheetURL, setSheetURL] = useState('');
    //Mapping headers to find relative header data from transaction sheet 
    const [MapHeaders, setMapHeaders] = useState({map_date:"",map_amount:"",map_description:"",map_category:"",map_categoryGroup:"",map_account:""});
    // const { enableRoutes } = useSelector((state) => state.settings);

    useEffect(() => {
        let sheet_url = localStorage.getItem('gSheet_url');
        let sheetID = localStorage.getItem('gSheetID');
        let mappingHeaders = localStorage.getItem('mappingHeaders');
        if (sheetID && sheet_url) {
            setSheetURL(sheet_url);
            dispatch(setSaveGsheetID(sheetID));
            dispatch(setSaveGsheetIDURL(sheet_url));
        }
        if(mappingHeaders){
           debugger // eslint-disable-line no-debugger
            let obj = JSON.parse(mappingHeaders);
            setMapHeaders(obj);
        }
    }, []);

    const saveSettings = () => {
        localStorage.setItem('gSheet_url', sheetURL);
        dispatch(setSaveGsheetIDURL(sheetURL));
        getGsheetAcc();
        separatedSheetID();
    };
    const getGsheetAcc = async () => {
        try {
            const response = await fetch(process.env.REACT_APP_SERVER_URL + 'auth/google/sheet');
            if (response.ok) {
                const data = await response.json();
                // setConsentUrl(data.consentUrl);

                // Open the consent URL in a new window
                window.open(data.authorizationUrl, '_blank', 'location=yes,height=570,width=520,scrollbars=yes,status=yes');
                // Listen for messages from the popup window
                window.addEventListener('message', (event) => {
                    // Check if the message is from the consent window and contains authentication success information
                    if (event.data && event.data.success) {
                        if (event.data.token) {
                            localStorage.setItem('gToken', JSON.stringify(event.data.token));
                            localStorage.setItem('userToken',event.data.userToken);
                        }
                        // Handle the authentication success here
                        showenqueSnackbar('Gsheet Access Granted!', 'success');

                        // You can update your state or perform other actions here
                    }
                });
            } else {
                console.error('API request failed');
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };
    // const getGsheetAccess = () => {
    //     axios({
    //         method: 'get',
    //         url: process.env.REACT_APP_SERVER_URL + 'auth/gsheetAccess'
    //     })
    //         .then(function (response) {
    //             console.log(response);
    //             // Open the consent URL in a new window
    //             window.open(response.data.AccessGsheet, '_blank', 'location=yes,height=570,width=520,scrollbars=yes,status=yes');

    //             // Listen for messages from the popup window
    //             window.addEventListener('message', (event) => {
    //                 // Check if the message is from the consent window and contains authentication success information
    //                 // debugger; // eslint-disable-line no-debugger
    //                 if (event.data && event.data.success) {
    //                     if (event.data.token) {
    //                         localStorage.setItem('gToken', JSON.stringify(event.data.token));
    //                         localStorage.setItem('userToken', event.data.userToken);
    //                     }
    //                     console.log(event.data);
    //                     // Handle the authentication success here
    //                     if (event.data.message) {
    //                         showenqueSnackbar(event.data.message, 'info');
    //                     }

    //                     // You can update your state or perform other actions here
    //                 }
    //             });
    //         })
    //         .catch(function (err) {
    //             console.log(err);
    //         });
    // };

    const separatedSheetID = async () => {
        if (sheetURL && sheetURL.includes('/d/')) {
            let ID = sheetURL?.split('/d/')[1]?.split('/')[0];
            localStorage.setItem('gSheetID', ID);
            dispatch(setSaveGsheetID(ID));
            let gsheetData = await getSheetData(ID, 'KFC', true);
            if (gsheetData.data && gsheetData.pages) {
                dispatch(setActivePages(gsheetData.pages));
            }
            showenqueSnackbar('Saved Successfully!', 'success');
        } else {
            showenqueSnackbar('Incorrect GSHEET URL', 'error');
        }
    };

    // const handleChangeTransaction = (e) => {
    //     dispatch(
    //         handleEnableRouting({
    //             assets: enableRoutes.assets,
    //             transaction: e.target.checked
    //         })
    //     );
    // };
    // const handleChangeAssets = (e) => {
    //     dispatch(
    //         handleEnableRouting({
    //             transaction: enableRoutes.transaction,
    //             assets: e.target.checked
    //         })
    //     );
    // };
    // const onSubmit = (event) => {
    //     event.preventDefault();
    //     // console.log('sheet', sheetUrl);
    //     // console.log('country', Country);
    //     // console.log('currency', currency);
    //     // console.log('dateFormatValue', dateFormatValue);
    //     // console.log('day', day);
    //     // console.log('financeMonth', financeMonth);

    //     // console.log('financeDate', financeDate);
    // };

    const onChangeMappingHeaders = (e) => { 
        setMapHeaders({
            ...MapHeaders,
            [e.target.name]:e.target.value
        })
    }
    const saveHeaderMapping = () => { 
        localStorage.setItem("mappingHeaders" , JSON.stringify(MapHeaders));
    }
    return (
        <MainCard title="Google G-Sheet">
            <Grid container spacing={gridSpacing}>
                <Grid item xs={12} md={12}>
                    <SubCard
                        title="Google G-Sheet Url"
                        secondary={
                            <Box display={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }} item>
                                <IconFileSpreadsheet color="green" />{' '}
                                <Typography
                                    href="https://docs.google.com/spreadsheets/d/1L5Awg4zHbhhMrE3qUl-YYSApp8jQH_CHdI8UUrjSsHA/edit?pli=1#gid=1022626307"
                                    target="_blank"
                                    component={Link}
                                    underline="always"
                                    color={'blue'}
                                    style={{ fontSize: '15px' }}
                                    variant="h5"
                                >
                                    Intelligent Money Hub G-Sheet V1.0
                                </Typography>
                            </Box>
                        }
                    >
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    value={sheetURL}
                                    onChange={(e) => setSheetURL(e.target.value)}
                                    id="outlined-basic"
                                    label="Google G-Sheet Url"
                                    placeholder="https://docs.google.com/spreadsheets/d/xxxxxxxxxxxxxx/edit#gid=xxxxxxxxx"
                                />
                            </Grid>
                        </Grid>
                        <Grid container style={{ marginTop: '10px' }} justifyContent="space-between" spacing={0}>
                            <Grid item></Grid>
                            <Grid item>
                                <AnimateButton>
                                    <Button variant="contained" style={{ marginTop: '5px' }} size="large" onClick={saveSettings}>
                                        Save
                                    </Button>
                                </AnimateButton>
                            </Grid>
                        </Grid>
                    </SubCard>
                </Grid>
                <Grid item xs={12} md={12}>
                    <SubCard
                        title="Transactions"
                        secondary={
                            <Box display={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }} item>
                                {/* <Typography variant="h5">Enable</Typography> */}
                                {/* <Switch
                                    name="transaction"
                                    checked={enableRoutes.transaction}
                                    onChange={(e) => handleChangeTransaction(e)}
                                    defaultChecked
                                    inputProps={{ 'aria-label': 'checked' }}
                                /> */}
                            </Box>
                        }
                    >
                        <Grid container spacing={3}>
                            {/* <Grid item xs={12}>
                                <TextField fullWidth id="outlined-basic" label="Worksheet Name" />
                            </Grid> */}
                            <Grid item xs={12}>
                                <TextField fullWidth id="outlined-basic" name='map_date' value={MapHeaders.map_date} onChange={onChangeMappingHeaders} label="Date Header Title" />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField fullWidth id="outlined-basic" name='map_amount' value={MapHeaders.map_amount} onChange={onChangeMappingHeaders} label="Amount Header Title" />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField fullWidth id="outlined-basic" name='map_description' value={MapHeaders.map_description} onChange={onChangeMappingHeaders} label="Description Header Title" />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField fullWidth id="outlined-basic" name='map_category' value={MapHeaders.map_category} onChange={onChangeMappingHeaders} label="Category Header Title" />
                            </Grid>
                            {/* <Grid item xs={12}>
                                <TextField fullWidth id="outlined-basic" name='map_categoryGroup' value={MapHeaders.map_categoryGroup} onChange={onChangeMappingHeaders} label="Category Group Header Title" />
                            </Grid> */}
                            <Grid item xs={12}>
                                <TextField fullWidth id="outlined-basic" name='map_account' value={MapHeaders.map_account} onChange={onChangeMappingHeaders} label="Account Header Title" />
                            </Grid>
                        </Grid>
                        <Grid container style={{ marginTop: '10px' }} justifyContent="space-between" spacing={0}>
                            <Grid item></Grid>
                            <Grid item>
                                <AnimateButton>
                                    <Button variant="contained" style={{ marginTop: '5px' }} size="large" onClick={(e) => saveHeaderMapping(e)}>
                                        Save
                                    </Button>
                                </AnimateButton>
                            </Grid>
                        </Grid>
                    </SubCard>
                </Grid>
                {/* <Grid item xs={12} md={12}>
                    <SubCard
                        title="Assets"
                        secondary={
                            <Box display={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }} item>
                                <Typography variant="h5">Enable</Typography>

                                <Switch
                                    name="assets"
                                    checked={enableRoutes.assets}
                                    onChange={(e) => handleChangeAssets(e)}
                                    defaultChecked
                                    inputProps={{ 'aria-label': 'checked' }}
                                />
                            </Box>
                        }
                    >
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <TextField fullWidth id="outlined-basic" label="Date Hearer Title" />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField fullWidth id="outlined-basic" label="Amount Header Title" />
                            </Grid>
                        </Grid>
                        <Grid container style={{ marginTop: '10px' }} justifyContent="space-between" spacing={0}>
                            <Grid item></Grid>
                            <Grid item>
                                <AnimateButton>
                                    <Button variant="contained" style={{ marginTop: '5px' }} size="large" onClick={(e) => onSubmit(e)}>
                                        Save
                                    </Button>
                                </AnimateButton>
                            </Grid>
                        </Grid>
                    </SubCard>
                </Grid> */}
            </Grid>
        </MainCard>
    );
};

export default GSheetDataMapping;
