import { memo, useEffect, useState } from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Box, Divider, List, Typography, useMediaQuery } from '@mui/material';

// project imports
import NavItem from './NavItem';
import menuItemimp from 'menu-items';
import NavGroup from './NavGroup';
import useConfig from 'hooks/useConfig';

import { useSelector } from 'store';
import LAYOUT_CONST, { ALLPAGES_MENU_ITEMS } from 'constant';
import { HORIZONTAL_MAX_ITEM } from 'config';
import { FormattedMessage } from 'react-intl';
import { IconBrandChrome, IconSettingsFilled } from '@tabler/icons-react';

// ==============================|| SIDEBAR MENU LIST ||============================== //

const icons = {
    IconBrandChrome,
    IconSettingsFilled
};

const updateSidebarMenuItems = (enableRoute) => {
    // debugger  // eslint-disable-line no-debugger

    let copyroutes = [...menuItemimp.items];
    let mainroutes = copyroutes[0];

    ALLPAGES_MENU_ITEMS.map((x) => {
        if (enableRoute[x.name]) {
            if (enableRoute[x.name] === 'No') {
                mainroutes.children = mainroutes.children.filter((child) => child.url !== x.url);
            } else {
                const isItemPresent = mainroutes?.children?.some((child) => child.url === x.url);
                if (!isItemPresent) {
                    mainroutes.children.push({
                        id: x.id,
                        title: <FormattedMessage id={x.name} />,
                        type: x.type,
                        url: x.url,
                        icon: icons[x.icon]
                    });
                }
            }
        }
    });

    copyroutes[0] = { ...mainroutes };

    return { items: copyroutes };
};

const MenuList = () => {
    const theme = useTheme();
    const { layout } = useConfig();
    const { drawerOpen } = useSelector((state) => state.menu);
    const enableRoute = useSelector((state) => state.sankey.activePages);
    const matchDownMd = useMediaQuery(theme.breakpoints.down('md'));
    const [menuItem, setMenuItem] = useState(menuItemimp);
    // last menu-item to show in horizontal menu bar
    const lastItem = layout === LAYOUT_CONST.HORIZONTAL_LAYOUT && !matchDownMd ? HORIZONTAL_MAX_ITEM : null;

    let lastItemIndex = menuItem.items.length - 1;
    let remItems = [];
    let lastItemId;

    if (lastItem && lastItem < menuItem.items.length) {
        lastItemId = menuItem.items[lastItem - 1].id;
        lastItemIndex = lastItem - 1;
        remItems = menuItem.items.slice(lastItem - 1, menuItem.items.length).map((item) => ({
            title: item.title,
            elements: item.children,
            icon: item.icon,
            ...(item.url && {
                url: item.url
            })
        }));
    }

    useEffect(() => {
        setMenuItem(updateSidebarMenuItems(enableRoute));
    }, [enableRoute]);

    const navItems = menuItem.items.slice(0, lastItemIndex + 1).map((item) => {
        switch (item.type) {
            case 'group':
                if (item.url && item.id !== lastItemId) {
                    console.log('item.url', item.url);
                    return (
                        <List
                            key={item.id}
                            {...(drawerOpen && layout !== LAYOUT_CONST.HORIZONTAL_LAYOUT && { sx: { mt: 1.5 } })}
                            disablePadding={!drawerOpen || layout === LAYOUT_CONST.HORIZONTAL_LAYOUT}
                        >
                            <NavItem item={item} level={1} isParents />
                            {layout !== LAYOUT_CONST.HORIZONTAL_LAYOUT && <Divider sx={{ py: 0.5 }} />}
                        </List>
                    );
                }
                return <NavGroup key={item.id} item={item} lastItem={lastItem} remItems={remItems} lastItemId={lastItemId} />;
            default:
                return (
                    <Typography key={item.id} variant="h6" color="error" align="center">
                        Menu Items Errorssss
                    </Typography>
                );
        }
    });

    return layout === LAYOUT_CONST.VERTICAL_LAYOUT || (layout === LAYOUT_CONST.HORIZONTAL_LAYOUT && matchDownMd) ? (
        <Box {...(drawerOpen && { sx: { mt: 1.5 } })}>{navItems}</Box>
    ) : (
        <>{navItems}</>
    );
};

export default memo(MenuList);
