import React, { useEffect, useState } from 'react';

// material-ui
import { Button, Step, Stepper, StepLabel, Stack, Typography } from '@mui/material';

// project imports
// import MainCard from 'ui-component/cards/MainCard';
import AnimateButton from 'ui-component/extended/AnimateButton';
import UserSettingsForm from './UserSettingsForm';
import GSheetTerms from './GsheetTerms';
import { useAuth } from 'utils/route-guard/OutsetaGuard';
import axios from 'axios';
import { showenqueSnackbar } from 'Helpers/gsheetHelpers';
import { setSaveGsheetID, setSaveGsheetIDURL } from 'store/slices/SankeyChart';
import { useDispatch } from 'react-redux';

// step options
const steps = ['User Settings', 'Connect Gsheet'];

const getStepContent = (
    step,
    handleNext,
    handleBack,
    setErrorIndex,
    UserSettings,
    setUserSettings,
    connectToSheet,
    setConnectToSheet,
    setGSheetURLCheck,
    setMapHeaders,
    gSheetURLCheck,
    MapHeaders,
    setMappingHeadersError,
    mappingHeadersError
) => {
    switch (step) {
        case 0:
            return (
                <UserSettingsForm
                    handleNext={handleNext}
                    setErrorIndex={setErrorIndex}
                    UserSettings={UserSettings}
                    setUserSettings={setUserSettings}
                />
            );
        case 1:
            return (
                <GSheetTerms
                    handleBack={handleBack}
                    connectToSheet={connectToSheet}
                    setConnectToSheet={setConnectToSheet}
                    setGSheetURLCheck={setGSheetURLCheck}
                    gSheetURLCheck={gSheetURLCheck}
                    setMapHeaders={setMapHeaders}
                    MapHeaders={MapHeaders}
                    setMappingHeadersError={setMappingHeadersError}
                    mappingHeadersError={mappingHeadersError}
                />
            );
        default:
            throw new Error('Unknown step');
    }
};

// ==============================|| FORMS WIZARD - BASIC ||============================== //

const ValidationWizard = ({ handleClose }) => {
    const dispatch = useDispatch();
    const { user } = useAuth();
    const [activeStep, setActiveStep] = React.useState(0);
    const [UserSettings, setUserSettings] = useState({});
    const [connectToSheet, setConnectToSheet] = useState('');
    const [errorIndex, setErrorIndex] = React.useState(null);
    const [gSheetURLCheck, setGSheetURLCheck] = useState(false);
    const [MapHeaders, setMapHeaders] = useState({map_date:"",map_amount:"",map_description:"",map_category:"",map_account:""});
    const [mappingHeadersError, setMappingHeadersError] = useState([]);


    const handleNext = () => {
        setActiveStep(activeStep + 1);
        setErrorIndex(null);
    };

    const handleBack = () => {
        setActiveStep(activeStep - 1);
    };

    useEffect(() => {
        console.log(activeStep);
    }, [activeStep]);
    useEffect(() => {
        console.log(mappingHeadersError);
    }, [mappingHeadersError]);

    const SubmitSettings = async () => {
        console.log(UserSettings);
        console.log(connectToSheet);
         debugger; // eslint-disable-line no-debugger
         let errorsarr = [];
        if(MapHeaders.map_date === ""){
            errorsarr.push( "map_date");
        } 
         if (MapHeaders.map_amount === "") {
            errorsarr.push("map_amount");
        }
         if (MapHeaders.map_description === "") {
            errorsarr.push("map_description");
        }
         if (MapHeaders.map_category === "") {
            errorsarr.push("map_category");
        }
         if (MapHeaders.map_account === "") {
            errorsarr.push("map_account");
        }
        if(errorsarr.length > 0){
            setMappingHeadersError(errorsarr)
        }
      
        if (connectToSheet === '') {
            setGSheetURLCheck(true);
            return false;
        }

      
        getGsheetAcc();
    };

    const updateUserProfileSettings = (data, connectToSheet) => {
        //  debugger; // eslint-disable-line no-debugger

        let datas = {
            id: '',
            outsetaUserID: user.Uid,
            ...data
        };

        axios({
            method: 'post',
            url: process.env.REACT_APP_SERVER_URL + 'api/save_profile_settings',
            data: datas,
            headers: {
                // Authorization: `Bearer ${localStorage.getItem('userToken')}`
                Authorization: `Bearer ${localStorage.getItem('OaccessToken')}`
            }
        })
            .then(function (response) {
                console.log(response);
                saveSettings(connectToSheet);
                handleClose();
            })
            .catch(function (err) {
                console.log(err);
            });
    };

    const getGsheetAcc = async () => {
        try {
            const response = await fetch(process.env.REACT_APP_SERVER_URL + 'auth/google/sheet');
            if (response.ok) {
                const data = await response.json();
                // setConsentUrl(data.consentUrl);

                // Open the consent URL in a new window
                window.open(data.authorizationUrl, '_blank', 'location=yes,height=570,width=520,scrollbars=yes,status=yes');
                // Listen for messages from the popup window
                window.addEventListener('message', async (event) => {
                    // Check if the message is from the consent window and contains authentication success information
                    if (event.data && event.data.success) {
                        if (event.data.token) {
                            localStorage.setItem('gToken', JSON.stringify(event.data.token));
                            localStorage.setItem('userToken', event.data.userToken);
                            let check = await separatedSheetID();
                            if (check === 'continue') {
                                updateUserProfileSettings(UserSettings, connectToSheet);
                            }
                        }
                        // Handle the authentication success here
                        showenqueSnackbar('Gsheet Access Granted!', 'success');

                        // You can update your state or perform other actions here
                    }
                });
            } else {
                console.error('API request failed');
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };
    const saveHeaderMapping = () => { 
        localStorage.setItem("mappingHeaders" , JSON.stringify(MapHeaders));
    }
    const saveSettings = (connectToSheet) => {
        localStorage.setItem('gSheet_url', connectToSheet);
        saveHeaderMapping();
        dispatch(setSaveGsheetIDURL(connectToSheet));
    };

    const separatedSheetID = async () => {
        if (connectToSheet && connectToSheet.includes('/d/')) {
            let ID = connectToSheet?.split('/d/')[1]?.split('/')[0];
            localStorage.setItem('gSheetID', ID);
            dispatch(setSaveGsheetID(ID));
            showenqueSnackbar('Welcome User!', 'success');
            return 'continue';
        } else {
            showenqueSnackbar('Incorrect GSHEET URL', 'error');
            return 'stop';
        }
    };

    return (
        <div title="Validation">
            <Stepper activeStep={activeStep} sx={{ pt: 3, pb: 5 }}>
                {steps.map((label, index) => {
                    const labelProps = {};

                    if (index === errorIndex) {
                        labelProps.optional = (
                            <Typography variant="caption" color="error">
                                Error
                            </Typography>
                        );

                        labelProps.error = true;
                    }

                    return (
                        <Step key={label}>
                            <StepLabel {...labelProps}>{label}</StepLabel>
                        </Step>
                    );
                })}
            </Stepper>
            <>
                <>
                    {getStepContent(
                        activeStep,
                        handleNext,
                        handleBack,
                        setErrorIndex,
                        UserSettings,
                        setUserSettings,
                        connectToSheet,
                        setConnectToSheet,
                        setGSheetURLCheck,
                        setMapHeaders,
                        gSheetURLCheck,
                        MapHeaders,
                        setMappingHeadersError,
                        mappingHeadersError
                    )}
                    {activeStep === steps.length - 1 && (
                        <Stack direction="row" justifyContent={activeStep !== 0 ? 'space-between' : 'flex-end'}>
                            {activeStep - 1 !== 0 && (
                                <Button onClick={handleBack} sx={{ my: 3, ml: 1 }}>
                                    Back
                                </Button>
                            )}
                            {activeStep === steps.length - 1 && <div></div>}
                            <AnimateButton>
                                {activeStep === steps.length - 1 ? (
                                    <>
                                        <Button variant="contained" onClick={SubmitSettings} sx={{ my: 3, ml: 1 }}>
                                            Submit
                                        </Button>
                                    </>
                                ) : (
                                    <Button variant="contained" onClick={handleNext} sx={{ my: 3, ml: 1 }}>
                                        Next
                                    </Button>
                                )}
                            </AnimateButton>
                        </Stack>
                    )}
                </>
            </>
        </div>
    );
};

export default ValidationWizard;
