const LAYOUT_CONST = {
    VERTICAL_LAYOUT: 'vertical',
    HORIZONTAL_LAYOUT: 'horizontal',
    DEFAULT_DRAWER: 'default',
    MINI_DRAWER: 'mini-drawer'
};

export default LAYOUT_CONST;

export const ALLPAGES_MENU_ITEMS = [
    // {
    //    name: 'Transactions',
    //    url:'/transactionsAnalysis',
    //    id: 'IOTransactionDataAnalysis',
    //    type:"item",
    //    icon:"IconSettingsFilled"
    // },
    // {
    //     name:"Pensions",
    //     url:'/pensionAnalysis',
    //     id: 'PensionDataAnalysis',
    //     type:"item",
    //     icon:"IconSettingsFilled"
    // },
    // {
    //     name:'Assets',
    //     url:'/assetsAnalysis',
    //     id: 'AssetsAnalysis',
    //     type:"item",
    //     icon:"IconSettingsFilled"
    // }
];
