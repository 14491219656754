import { lazy } from 'react';

// project imports
// import AuthGuard from 'utils/route-guard/AuthGuard';
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import ProfilePage from 'views/profile';
import ChatPage from 'views/chat';
import OutSetaGuard from 'utils/route-guard/OutsetaGuard';
import ProtectedRoute from 'utils/route-guard/ProtectedRoutesOutseta';

// sample page routing
const HomePage = Loadable(lazy(() => import('views/dashboard/home')));
const TransactionPage = Loadable(lazy(() => import('views/dashboard/transaction')));
const AssetsPage = Loadable(lazy(() => import('views/dashboard/assets')));
// const SettingsPage = Loadable(lazy(() => import('views/settings-page')));
const LiabilitiesPage = Loadable(lazy(() => import('views/dashboard/liabilities')));
const CryptoPage = Loadable(lazy(() => import('views/dashboard/crypto')));
const PensionsPage = Loadable(lazy(() => import('views/dashboard/pensions')));
const LoansPage = Loadable(lazy(() => import('views/dashboard/loans')));
const MortgagesPage = Loadable(lazy(() => import('views/dashboard/mortgages')));
const ElectricityPage = Loadable(lazy(() => import('views/dashboard/electricity')));
const GasPage = Loadable(lazy(() => import('views/dashboard/gas')));
const WaterPage = Loadable(lazy(() => import('views/dashboard/water')));
const ContractsSubscriptionPage = Loadable(lazy(() => import('views/dashboard/contracts-subscription')));
const RetirementPage = Loadable(lazy(() => import('views/dashboard/retirement')));
const GSheetLinkPage = Loadable(lazy(() => import('views/gSheet/GSheetLink')));
const GSheetAccessPage = Loadable(lazy(() => import('views/gSheet/GSheetAccess')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
    path: '/',
    element: (
        // <AuthGuard>
        <OutSetaGuard>
            <ProtectedRoute>
                <MainLayout />
            </ProtectedRoute>
        </OutSetaGuard>
        // </AuthGuard>
    ),
    children: [
        {
            path: '/',
            element: <HomePage />
        },
        {
            path: '/sheet-access',
            element: <GSheetAccessPage />
        },
        {
            path: '/sheet-link',
            element: <GSheetLinkPage />
        },
        // {
        //     path: '/dashboard',
        //     element: <HomePage />
        // },
        {
            path: '/dashboard',
            element: <TransactionPage />
        },
        {
            path: '/dashboard/cash-flow/',
            element: <TransactionPage />
        },
        {
            path: '/dashboard/transactions/',
            element: <TransactionPage />
        },
        {
            path: '/dashboard/assets/',
            element: <AssetsPage />
        },
        {
            path: '/dashboard/liabilities/',
            element: <LiabilitiesPage />
        },
        {
            path: '/dashboard/crypto/',
            element: <CryptoPage />
        },
        {
            path: '/dashboard/pensions/',
            element: <PensionsPage />
        },
        {
            path: '/dashboard/loans/',
            element: <LoansPage />
        },
        {
            path: '/dashboard/mortgages/',
            element: <MortgagesPage />
        },
        {
            path: '/dashboard/electricity/',
            element: <ElectricityPage />
        },
        {
            path: '/dashboard/gas/',
            element: <GasPage />
        },
        {
            path: '/dashboard/water/',
            element: <WaterPage />
        },
        {
            path: '/dashboard/contracts-and-Subscriptions/',
            element: <ContractsSubscriptionPage />
        },
        {
            path: '/dashboard/retirement/',
            element: <RetirementPage />
        },
        // {
        //     path: '/settings',
        //     element: <SettingsPage />
        // },
        {
            path: '/profile',
            element: <ProfilePage />
        },
        {
            path: '/chat',
            element: <ChatPage />
        }
    ]
};

export default MainRoutes;
