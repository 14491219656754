// material-ui
// import { useTheme } from '@mui/material/styles';

/**
 * if you want to use image instead of <svg> uncomment following.
 *
 * import logoDark from 'assets/images/logo-dark.svg';
 * import logo from 'assets/images/logo.svg';
 *
 */

// ==============================|| LOGO SVG ||============================== //

const Logo = () => {
    // const theme = useTheme();

    return (
        /**
         * if you want to use image instead of svg uncomment following, and comment out <svg> element.
         *
         * <img src={theme.palette.mode === 'dark' ? logoDark : logo} alt="Berry" width="100" />
         *
         */

        <svg width="90%" height="40" viewBox="0 0 683 192" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M60.3646 124.136C60.3648 88.981 60.2628 54.3251 60.5003 19.6715C60.5287 15.5383 61.6702 11.0302 63.5915 7.37738C66.9087 1.07068 73.4173 -1.27466 80.2518 0.65915C104.252 7.44965 128.228 14.3246 152.179 21.2833C159.501 23.4106 164.365 29.4091 164.409 37.1547C164.588 68.809 164.591 100.465 164.439 132.12C164.401 140.115 159.537 145.959 151.899 148.234C141.86 151.224 131.739 153.949 121.757 157.113C119.44 157.848 116.999 159.489 115.541 161.414C109.009 170.041 102.898 178.986 96.4154 187.652C95.2107 189.263 93.0007 190.121 91.2548 191.327C90.5851 189.271 89.4296 187.236 89.3381 185.154C89.0761 179.197 89.2459 173.221 89.2459 166.654C86.47 167.287 84.2337 167.713 82.0441 168.31C70.1822 171.544 60.6965 164.559 60.4258 152.126C60.2264 142.967 60.3725 133.8 60.3646 124.136ZM126.191 57.6388C126.191 82.3701 126.191 107.102 126.191 132.283C130.739 131.027 134.529 129.812 138.398 128.963C141.563 128.269 142.439 126.675 142.42 123.442C142.27 97.6339 142.341 71.8246 142.322 46.0157C142.321 44.7374 142.104 43.4593 141.997 42.2803C136.58 42.2803 131.527 42.2803 126.191 42.2803C126.191 47.3476 126.191 51.9937 126.191 57.6388ZM120.519 117.634C120.519 99.716 120.519 81.7983 120.519 63.6996C114.871 63.6996 109.818 63.6996 104.763 63.6996C104.763 88.692 104.763 113.352 104.763 138.067C120.518 135.496 120.518 135.496 120.519 120.628C120.519 119.963 120.519 119.297 120.519 117.634ZM99.0202 138.836C99.0202 121.777 99.0202 104.718 99.0202 87.5976C93.2564 87.5976 88.1837 87.5976 82.9209 87.5976C82.9209 106.709 82.9209 125.515 82.9209 145.082C88.5745 143.148 93.6922 141.396 99.0202 138.836Z"
                fill="#2E92D0"
            />
            <path
                d="M53.1845 19.6583C53.1865 61.5724 53.223 103.042 53.1082 144.51C53.1003 147.368 52.8172 151.953 51.1919 152.755C46.225 155.206 40.5366 157.056 35.1248 153.114C30.2306 149.549 28.6299 144.513 28.6367 138.711C28.6594 119.392 28.6428 100.073 28.6426 80.7539C28.6425 64.0997 28.4974 47.4431 28.7636 30.7931C28.8197 27.2833 29.693 23.4072 31.3971 20.3794C35.3296 13.3925 46.1188 11.8561 52.4993 16.9344C53.0089 17.34 52.9679 18.4374 53.1845 19.6583Z"
                fill="#F39222"
            />
            <path
                d="M524.155 108.489C522.817 115.65 518.582 119.909 511.987 120.315C501.096 120.985 490.122 121.178 479.23 120.627C472.991 120.311 467.727 117.069 466.588 109.916C465.457 102.813 467.188 96.9928 474.586 93.6814C470.7 87.5134 470.422 81.7402 475.158 77.5453C472.912 73.0542 469.876 69.1242 468.972 64.7533C466.76 54.0599 472.1 46.6188 482.944 45.3055C490.137 44.4345 497.507 44.8258 504.791 44.9667C511.913 45.1045 519.027 45.6418 526.298 46.0115C527.814 51.4732 525.177 53.7035 519.903 53.6388C520.132 56.4458 520.474 58.902 520.507 61.3623C520.642 71.3275 516.455 76.6712 506.492 78.0454C499.454 79.016 492.277 78.9747 484.717 79.4108C483.251 82.681 484.231 84.891 488.509 85.403C494.95 86.174 501.403 86.8612 507.825 87.7719C521.558 89.7196 525.001 94.0426 524.155 108.489ZM483.974 108.296C490.857 108.595 497.753 109.204 504.615 108.939C506.046 108.884 508.276 105.668 508.416 103.781C508.524 102.327 506.173 99.7124 504.495 99.2917C499.846 98.126 494.991 97.6873 490.192 97.2368C482.802 96.543 480.17 100.707 483.974 108.296ZM505.308 61.6325C504.19 59.5557 503.316 55.9053 501.911 55.6885C497.126 54.9497 492.129 55.1585 487.272 55.6238C486.127 55.7334 484.698 58.1295 484.29 59.7043C483.037 64.55 484.986 67.6209 489.529 68.7487C498.016 70.8558 503.579 68.6226 505.308 61.6325Z"
                fill="#106DA7"
            />
            <path
                d="M539.861 99.8115C532.387 96.8448 527.736 91.4876 527.022 84.1193C526.264 76.2921 526.412 68.2224 527.423 60.4217C528.455 52.4581 534.006 47.5695 541.816 45.648C550.703 43.4616 559.644 43.4138 568.422 46.1299C578.81 49.3443 583.76 62.3757 578.305 71.8185C575.33 76.9702 570.175 77.8503 564.935 78.0095C557.962 78.2213 550.978 78.0618 544 78.0618C544.016 84.5927 546.187 87.894 552.21 88.4037C558.624 88.9465 565.135 88.3471 571.603 88.2228C573.411 88.1881 575.217 88.0699 577.281 87.9787C577.804 91.2482 578.297 94.3303 578.894 98.0661C566.022 102.338 553.281 103.607 539.861 99.8115ZM548.09 68.9465C551.565 68.9355 555.102 69.3113 558.491 68.7643C560.433 68.4509 563.376 66.8864 563.664 65.4454C564.146 63.0288 563.439 58.3509 562.225 57.9868C558.002 56.7207 553.275 56.2454 548.876 56.7216C544.906 57.1512 543.997 61.1067 543.403 64.6812C542.894 67.7434 543.583 69.5627 548.09 68.9465Z"
                fill="#106DA7"
            />
            <path
                d="M371.369 47.0434C379.729 52.1484 382.806 60.827 379.757 69.6095C377.429 76.3156 371.835 77.6054 365.812 78.0066C362.164 78.2496 358.488 78.0823 354.825 78.0898C351.367 78.0969 347.908 78.0913 344.44 78.0913C344.688 84.826 346.697 87.8699 352.591 88.3828C358.843 88.9268 365.193 88.3709 371.499 88.2557C373.465 88.2198 375.428 88.0552 377.72 87.9319C378.295 91.3276 378.812 94.3846 379.443 98.1085C365.532 102.309 351.818 104.461 338.021 98.6855C332.082 96.1992 328.268 91.0805 327.68 84.8933C326.917 76.875 326.742 68.6232 327.775 60.6611C328.822 52.5838 334.448 47.3995 342.525 45.5745C352.107 43.4097 361.665 43.3609 371.369 47.0434ZM345.015 60.4792C344.462 63.1264 343.908 65.7737 343.262 68.8685C349.28 68.8685 354.44 69.2 359.514 68.6877C361.276 68.5097 364.148 66.2843 364.192 64.9194C364.276 62.307 363.465 58.7497 361.647 57.1853C358.014 54.0583 348.876 55.9809 345.015 60.4792Z"
                fill="#0F6DA7"
            />
            <path
                d="M0.0208843 103.136C0.0213263 84.3206 -0.0253198 66.0049 0.0747402 47.69C0.0889762 45.0854 0.35248 42.2977 1.31173 39.9224C3.12733 35.4264 7.23414 33.6334 11.8764 34.8057C15.06 35.6097 18.1994 36.5894 21.4511 37.517C21.4511 68.9231 21.4511 100.031 21.4511 131.82C17.7285 132.856 13.863 134.355 9.86399 134.937C4.85868 135.666 0.429499 131.139 0.178584 125.109C-0.118833 117.962 0.0525153 110.795 0.0208843 103.136Z"
                fill="#DE015E"
            />
            <path
                d="M281.492 53.3461C281.847 59.3775 282.267 65.0063 282.339 70.6394C282.463 80.4467 282.373 90.2568 282.373 100.386C276.863 100.386 271.632 100.386 265.792 100.386C265.792 89.783 265.471 79.3392 265.881 68.9243C266.305 58.1856 263.886 56.2581 252.113 59.7156C246.672 61.3135 244.532 64.0435 244.886 69.994C245.477 79.9324 245.047 89.9315 245.047 100.306C239.552 100.306 234.331 100.306 228.767 100.306C228.767 82.2887 228.767 64.2512 228.77 46.2127C239.439 44.2337 240.867 44.8787 243.593 53.4193C245.295 52.2751 246.838 51.1777 248.439 50.1727C254.468 46.3872 260.903 43.8285 268.17 44.2432C274.203 44.5876 279.215 46.6638 281.492 53.3461Z"
                fill="#116EA7"
            />
            <path
                d="M584.743 99.5036C584.63 81.4637 584.63 63.8577 584.63 45.954C589.247 45.954 593.492 45.954 597.855 45.954C598.383 48.382 598.872 50.6304 599.495 53.4952C601.8 52.0208 603.763 50.672 605.812 49.473C612.58 45.5138 619.734 43.0603 627.692 44.6925C634.265 46.0408 638.186 50.0971 638.313 56.7052C638.59 71.1625 638.398 85.6288 638.398 100.396C633.082 100.396 627.98 100.396 622.128 100.396C622.128 97.3693 622.128 94.2803 622.128 91.1914C622.126 82.5382 622.21 73.8838 622.091 65.2322C622.01 59.3034 619.779 57.332 613.964 58.351C610.734 58.9168 607.663 60.3587 604.501 61.3413C601.839 62.1683 601.168 63.9115 601.206 66.623C601.359 77.7538 601.27 88.888 601.27 100.959C595.571 100.604 590.214 100.271 584.743 99.5036Z"
                fill="#0F6DA7"
            />
            <path
                d="M653.58 96.8653C652.362 92.9577 650.684 89.3695 650.532 85.7178C650.133 76.1112 650.397 66.4771 650.397 56.6862C646.904 56.5205 643.953 56.3806 640.877 56.2347C640.877 52.8174 640.877 49.7275 640.877 46.363C644.024 46.1575 646.939 45.9672 650.353 45.7443C650.353 40.5977 650.353 35.688 650.353 30.5693C655.984 30.5693 661.095 30.5693 666.76 30.5693C666.76 35.3546 666.76 40.1076 666.76 45.536C672.176 45.536 677.064 45.536 682.088 45.536C682.088 49.4883 682.088 52.7555 682.088 56.5092C677.113 56.5092 672.347 56.5092 667.07 56.5092C667.07 66.0923 666.863 75.2071 667.282 84.293C667.349 85.7437 670.023 87.761 671.843 88.2756C674.282 88.9654 677.063 88.4464 679.926 88.4464C680.564 92.4059 681.112 95.8073 681.764 99.8555C671.979 101.359 662.442 105.228 653.58 96.8653Z"
                fill="#0E6CA6"
            />
            <path
                d="M310.571 79.782C310.739 87.2308 311.561 88.8432 316.927 88.399C322.852 87.9086 325.021 90.2211 324.973 95.7078C324.962 96.8542 325.246 98.0033 325.482 99.8383C318.168 101.382 310.998 103.275 303.556 101.013C297.86 99.2826 294.491 94.634 294.337 88.1759C294.135 79.692 294.206 71.2017 294.163 62.7141C294.154 60.9143 294.162 59.1144 294.162 56.7349C290.857 56.5768 287.948 56.4376 284.75 56.2846C284.75 53.0541 284.75 49.9745 284.75 46.2987C287.53 46.1483 290.438 45.9909 293.904 45.8034C293.904 40.7553 293.904 35.8661 293.904 30.6616C299.68 30.6616 304.876 30.6616 310.596 30.6616C310.596 35.6091 310.596 40.3576 310.596 45.568C315.869 45.568 320.631 45.568 325.746 45.568C325.746 49.26 325.746 52.496 325.746 56.3604C320.931 56.3604 316.034 56.3604 310.571 56.3604C310.571 64.4194 310.571 71.87 310.571 79.782Z"
                fill="#0E6CA6"
            />
            <path
                d="M446.45 99.0613C440.035 99.8839 433.548 101.72 427.224 101.265C419.795 100.731 415.673 95.4194 415.608 87.9772C415.419 66.5079 415.541 45.0358 415.541 23.1782C421.101 23.1782 426.46 23.1782 432.09 23.1782C432.09 39.8345 432.09 56.2978 432.09 72.7611C432.09 74.0933 432.085 75.4254 432.082 76.7576C432.061 87.6832 432.061 87.6832 442.543 88.9382C443.579 92.2562 444.624 95.6024 446.074 98.796C446.48 98.6434 446.45 99.0613 446.45 99.0613Z"
                fill="#136FA8"
            />
            <path
                d="M401.589 67.1431C401.584 72.4706 401.575 77.3007 401.575 82.1308C401.575 85.8264 403.163 88.6568 407.059 88.3477C412.314 87.9309 413.706 90.4786 413.722 94.9838C413.727 96.4388 414.21 97.8922 414.555 99.7843C407.926 101.742 401.526 103.037 394.892 101.03C389.31 99.342 385.498 94.799 385.456 88.867C385.304 67.0628 385.396 45.2568 385.396 23.1333C390.827 23.1333 396.038 23.1333 401.59 23.1333C401.59 37.7519 401.59 52.1988 401.589 67.1431Z"
                fill="#0D6BA6"
            />
            <path
                d="M446.604 99.587C446.451 99.0613 446.48 98.6434 446.485 98.533C446.489 81.1232 446.489 63.8238 446.489 46.1406C451.651 46.1406 456.715 46.1406 462.162 46.1406C462.162 64.0083 462.162 82.0451 462.162 100.113C456.936 100.113 451.847 100.113 446.604 99.587Z"
                fill="#0B6AA5"
            />
            <path
                d="M204.4 58.1474C204.4 53.8537 204.4 50.0575 204.4 46.0166C209.909 46.0166 214.974 46.0166 220.359 46.0166C220.359 64.0649 220.359 81.9502 220.359 100.214C215.253 100.214 210.186 100.214 204.4 100.214C204.4 86.4592 204.4 72.5521 204.4 58.1474Z"
                fill="#0B6AA5"
            />
            <path
                d="M307.721 153.966C317.345 151.653 321.191 154.514 321.288 163.693C321.331 167.858 321.45 172.033 321.206 176.184C321.131 177.468 319.975 178.689 319.313 179.938C318.577 178.754 317.279 177.605 317.204 176.38C316.94 172.064 317.142 167.722 317.075 163.391C317.023 160.038 316.5 156.908 312.168 156.651C307.777 156.391 305.001 158.487 304.646 162.826C304.268 167.462 304.598 172.153 304.333 176.804C304.271 177.879 303.049 178.888 302.36 179.928C301.699 178.861 300.521 177.819 300.468 176.723C300.248 172.235 300.401 167.73 300.36 163.232C300.328 159.762 299.439 156.833 295.236 156.644C291.121 156.458 288.319 158.296 288.13 162.572C287.881 168.193 288.068 173.832 288.068 179.464C287.431 179.757 286.794 180.05 286.157 180.343C285.274 179.24 283.667 178.163 283.627 177.03C283.388 170.208 283.462 163.372 283.558 156.542C283.572 155.538 284.262 154.543 284.639 153.543C285.78 154.165 286.921 154.786 288.375 155.578C294.936 151.729 298.087 151.896 303.796 156.742C304.991 155.86 306.177 154.986 307.721 153.966Z"
                fill="#3897D3"
            />
            <path
                d="M578.586 180.136C574.761 179.546 571.301 178.909 567.898 178.283C567.607 178.853 566.98 180.081 566.353 181.309C565.734 180.85 565.114 180.39 564.494 179.931C564.273 178.878 563.864 177.825 563.859 176.771C563.811 166.462 563.757 156.152 563.93 145.846C563.949 144.674 565.347 143.526 566.106 142.366C566.778 143.617 567.899 144.822 568.031 146.127C568.324 149.029 568.122 151.98 568.122 155.077C578.383 151.76 583.671 153.244 586.14 159.986C588.748 167.108 587.196 178.263 578.586 180.136ZM579.518 175.864C583.18 171.704 584.52 162.8 581.114 159.451C579.299 157.665 575.38 156.557 572.921 157.175C566.85 158.7 568.126 164.307 568.255 168.764C568.453 175.595 572.079 178.004 579.518 175.864Z"
                fill="#3897D3"
            />
            <path
                d="M445.318 163.089C446.788 166.997 448.14 170.536 449.855 175.025C451.616 170.122 452.678 165.952 454.591 162.218C456.172 159.132 454.966 153.534 461.898 153.444C460.216 158.073 458.802 162.059 457.321 166.021C454.995 172.238 452.674 178.458 450.253 184.638C448.617 188.813 445.805 191.758 441.016 191.686C439.721 191.667 438.443 190.507 437.745 190.162C440.991 187.834 444.232 186.416 445.981 183.942C447.19 182.231 447.054 178.815 446.282 176.591C443.634 168.969 440.44 161.537 437.241 153.497C444.675 153.226 442.609 159.895 445.318 163.089Z"
                fill="#3897D3"
            />
            <path
                d="M428.108 160.861C428.312 163.059 428.405 164.864 428.535 167.412C422.553 167.412 416.863 167.412 411.213 167.412C410.942 176.041 415.326 178.127 427.712 176.238C426.86 177.311 426.229 178.879 425.12 179.374C418.935 182.134 411.391 180.044 408.208 174.936C404.813 169.488 405.805 160.056 410.212 155.878C415.88 150.504 424.411 152.706 428.108 160.861ZM412.985 158.222C412.118 160.006 411.252 161.791 410.216 163.923C415.401 163.923 419.669 163.923 423.955 163.923C423.292 156.851 420.067 154.996 412.985 158.222Z"
                fill="#3897D3"
            />
            <path
                d="M495.377 167.363C495.375 170.997 495.527 174.174 495.273 177.318C495.199 178.235 493.965 179.059 493.262 179.926C492.618 179.007 491.424 178.095 491.412 177.168C491.28 166.511 491.28 155.851 491.41 145.193C491.421 144.252 492.592 143.324 493.224 142.39C493.94 143.412 495.187 144.391 495.275 145.465C495.546 148.769 495.373 152.109 495.373 155.87C496.961 155.08 498.198 154.352 499.517 153.828C506.968 150.866 513.164 154.812 513.349 162.688C513.459 167.349 513.495 172.02 513.284 176.674C513.234 177.784 512.065 178.843 511.41 179.925C510.716 178.889 509.489 177.885 509.424 176.811C509.183 172.828 509.318 168.822 509.326 164.825C509.335 160.776 509.063 156.937 503.67 156.604C498.564 156.288 495.976 158.717 495.425 164.397C495.345 165.223 495.391 166.061 495.377 167.363Z"
                fill="#3897D3"
            />
            <path
                d="M358.329 166.212C357.815 174.862 354.569 179.351 348.442 180.166C343.462 180.827 339.168 179.633 336.258 175.191C333.153 170.454 333.26 162.227 336.493 157.627C339.591 153.219 344.103 152.528 348.944 153.27C354.324 154.094 357.334 158.276 358.329 166.212ZM345.911 156.393C338.894 158.079 336.027 164.968 339.243 172.418C341.4 177.415 347.756 178.621 351.572 174.756C356.986 169.274 354.126 158.564 345.911 156.393Z"
                fill="#3897D3"
            />
            <path
                d="M462.838 33.3104C460.823 37.1723 455.784 38.4947 449.713 37.2082C445.592 36.3347 445.804 33.0469 446.116 30.1666C446.414 27.4156 447.017 22.7727 448.373 22.4252C452.315 21.4154 456.736 21.8732 460.886 22.4033C461.771 22.5164 462.629 25.3115 462.919 26.9724C463.255 28.8921 462.95 30.9239 462.838 33.3104Z"
                fill="#136FA8"
            />
            <path
                d="M209.965 37.3062C205.767 37.4549 204.107 35.5328 204.201 31.8506C204.251 29.8657 203.678 27.6389 204.402 25.9588C205.174 24.1659 206.904 22.1137 208.649 21.5741C211.065 20.8269 214.099 20.7667 216.464 21.5839C218.343 22.2331 219.863 24.4802 221.052 26.3332C221.657 27.2767 221.203 28.9148 221.186 30.2384C221.113 36.1998 220.114 37.2295 214.345 37.308C213.021 37.326 211.697 37.3098 209.965 37.3062Z"
                fill="#136FA8"
            />
            <path
                d="M389.351 162.68C389.015 158.442 386.989 156.55 383.154 156.593C379.12 156.639 376.236 158.434 375.822 162.532C375.356 167.144 375.668 171.83 375.32 176.46C375.23 177.662 373.941 178.774 373.203 179.927C372.594 178.811 371.48 177.706 371.457 176.578C371.301 168.966 371.376 161.35 371.376 152.483C373.48 153.926 374.668 154.741 376.117 155.734C377.115 155.194 378.456 154.264 379.936 153.701C387.19 150.942 393.202 154.855 393.389 162.482C393.507 167.302 393.54 172.131 393.318 176.943C393.271 177.968 391.954 178.934 391.222 179.927C390.608 178.984 389.501 178.061 389.462 177.094C389.272 172.446 389.371 167.786 389.351 162.68Z"
                fill="#3897D3"
            />
            <path
                d="M548.974 153.973C549.244 162.752 549.244 171.189 549.244 180.658C547.255 179.434 545.934 178.621 545.577 178.402C541.497 179.047 538.015 180.066 534.545 180.027C530.358 179.98 527.742 177.195 527.507 173.037C527.189 167.408 527.289 161.747 527.442 156.107C527.467 155.199 528.699 154.325 529.373 153.435C530.056 154.312 531.291 155.166 531.33 156.071C531.532 160.712 531.468 165.367 531.416 170.016C531.377 173.573 532.464 176.411 536.388 176.827C540.356 177.248 544.052 176.161 544.661 171.625C545.318 166.727 545.273 161.721 545.333 156.76C545.363 154.31 545.816 152.785 548.974 153.973Z"
                fill="#3897D3"
            />
            <path
                d="M126.191 57.1392C126.191 51.9936 126.191 47.3475 126.191 42.2803C131.527 42.2803 136.58 42.2803 141.997 42.2803C142.104 43.4592 142.32 44.7374 142.321 46.0157C142.341 71.8246 142.27 97.6339 142.42 123.442C142.439 126.675 141.563 128.269 138.398 128.963C134.529 129.812 130.739 131.027 126.191 132.283C126.191 107.101 126.191 82.3701 126.191 57.1392Z"
                fill="#FDFEFF"
            />
            <path
                d="M120.518 118.133C120.518 119.297 120.518 119.963 120.518 120.628C120.518 135.496 120.518 135.496 104.763 138.067C104.763 113.352 104.763 88.6922 104.763 63.6997C109.818 63.6997 114.871 63.6997 120.518 63.6997C120.518 81.7985 120.518 99.7161 120.518 118.133Z"
                fill="#FDFEFF"
            />
            <path
                d="M98.9151 139.241C93.6922 141.397 88.5745 143.148 82.9209 145.082C82.9209 125.515 82.9209 106.709 82.9209 87.5977C88.1838 87.5977 93.2564 87.5977 99.0203 87.5977C99.0203 104.718 99.0203 121.777 98.9151 139.241Z"
                fill="#F4F9FD"
            />
            <path
                d="M483.662 108.055C480.17 100.707 482.802 96.5428 490.192 97.2365C494.991 97.6871 499.846 98.1257 504.495 99.2915C506.173 99.7122 508.524 102.327 508.416 103.78C508.276 105.668 506.046 108.884 504.615 108.939C497.753 109.203 490.857 108.594 483.662 108.055Z"
                fill=""
            />
            <path
                d="M505.296 62.0286C503.58 68.6228 498.016 70.856 489.529 68.7488C484.986 67.621 483.037 64.5501 484.29 59.7044C484.698 58.1296 486.127 55.7336 487.272 55.6239C492.129 55.1586 497.126 54.9498 501.911 55.6886C503.316 55.9055 504.19 59.5558 505.296 62.0286Z"
                fill=""
            />
            <path
                d="M547.622 68.9466C543.583 69.563 542.894 67.7437 543.403 64.6815C543.997 61.107 544.906 57.1515 548.876 56.7219C553.275 56.2457 558.002 56.721 562.225 57.9871C563.439 58.3512 564.146 63.0291 563.664 65.4457C563.376 66.8867 560.433 68.4512 558.491 68.7646C555.102 69.3116 551.565 68.9358 547.622 68.9466Z"
                fill=""
            />
            <path
                d="M345.193 60.1246C348.876 55.9809 358.013 54.0583 361.647 57.1854C363.465 58.7497 364.276 62.307 364.192 64.9195C364.148 66.2844 361.276 68.5098 359.514 68.6877C354.44 69.2001 349.28 68.8685 343.262 68.8685C343.908 65.7737 344.461 63.1265 345.193 60.1246Z"
                fill=""
            />
            <path
                d="M579.165 176.045C572.079 178.005 568.453 175.595 568.255 168.765C568.126 164.307 566.85 158.7 572.921 157.175C575.38 156.558 579.299 157.665 581.114 159.451C584.52 162.801 583.18 171.704 579.165 176.045Z"
                fill=""
            />
            <path
                d="M413.277 157.976C420.067 154.996 423.293 156.851 423.955 163.924C419.669 163.924 415.401 163.924 410.216 163.924C411.252 161.791 412.119 160.006 413.277 157.976Z"
                fill=""
            />
            <path
                d="M346.292 156.405C354.125 158.564 356.986 169.274 351.572 174.756C347.755 178.621 341.4 177.415 339.242 172.418C336.027 164.968 338.893 158.079 346.292 156.405Z"
                fill=""
            />
        </svg>
    );
};

export default Logo;
