import { useNavigate } from 'react-router-dom';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Avatar, Chip } from '@mui/material';

// assets
import { IconSettings } from '@tabler/icons-react';

// ==============================|| PROFILE MENU ||============================== //

const ProfileSection = () => {
    const theme = useTheme();
    const navigate = useNavigate();

    const handleToggle = () => {
        navigate('/profile');
    };

    return (
        <Chip
            sx={{
                height: '48px',
                alignItems: 'center',
                borderRadius: '27px',
                transition: 'all .2s ease-in-out',
                borderColor: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.primary.light,
                backgroundColor: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.primary.light,
                '&[aria-controls="menu-list-grow"], &:hover': {
                    borderColor: theme.palette.primary.main,
                    background: `${theme.palette.primary.main}!important`,
                    color: theme.palette.primary.light,
                    '& svg': {
                        stroke: theme.palette.primary.light
                    }
                },
                '& .MuiChip-label': {
                    lineHeight: 0
                }
            }}
            icon={
                <Avatar
                    sx={{
                        ...theme.typography.mediumAvatar,
                        margin: '8px 0 8px 8px !important',
                        cursor: 'pointer'
                    }}
                    aria-haspopup="true"
                    color="inherit"
                    alt="user-account"
                />
            }
            label={<IconSettings stroke={1.5} size="24px" color={theme.palette.primary.main} />}
            variant="outlined"
            onClick={handleToggle}
            color="primary"
        />
    );
};

export default ProfileSection;
