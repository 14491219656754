import { useEffect, useState, createContext, useContext, useRef } from 'react';
import { useSearchParams } from 'react-router-dom';

import { loadOutseta } from './outseta';

/*
 * Setting up a auth context to be used globally
 *
 */

const AuthContext = createContext({});

export function useAuth() {
    return useContext(AuthContext);
}

export default function AuthProvider({ children }) {
    const [searchParams, setSearchParams] = useSearchParams();
    const [status, setStatus] = useState('init');
    const [user, setUser] = useState();
    const outsetaRef = useRef();

    useEffect(() => {
        const init = async () => {
            // debugger  // eslint-disable-line no-debugger
            // Await injection of the script
            outsetaRef.current = await loadOutseta();

            // Set up handling of user related events
            handleOutsetaUserEvents(updateUser);

            // Get the access token from the callback url
            const accessToken = searchParams.get('access_token');

            if (accessToken) {
                // If there is an acccess token present
                // pass it along to Outseta
                outsetaRef.current.setAccessToken(accessToken);
                localStorage.setItem("OaccessToken",accessToken)
                // and clean up
                setSearchParams({});
            }

            if (outsetaRef.current.getAccessToken()) {
                // Outseta initalized with an authenticated user.
                localStorage.setItem("OaccessToken",outsetaRef.current.getAccessToken())
                updateUser();
            } else {
                // Outseta initalized without authenticated user.
                setStatus('ready');
            }
        };

        init();

        return () => {
            // Clean up user related event subscriptions
            handleOutsetaUserEvents(() => {});
        };
    }, [searchParams, setSearchParams]);

    const updateUser = async () => {
        // Fetch the current user data from outseta
        const outsetaUser = await outsetaRef.current.getUser();
        // Update user state
        setUser(outsetaUser);
        // Make sure status = ready
        setStatus('ready');
    };

    const handleOutsetaUserEvents = (onEvent) => {
        // Subscribe to user related events
        // with onEvent function
        const outseta = outsetaRef.current;
        outseta.on('subscription.update', onEvent);
        outseta.on('profile.update', onEvent);
        outseta.on('account.update', onEvent);
    };

    const logout = () => {
        // Unset access token
        outsetaRef.current.setAccessToken('');
        // and remove user state
        setUser(null);
    };

    const openLogin = async (options) => {
        outsetaRef.current.auth.open({
            widgetMode: 'login|register',
            authenticationCallbackUrl: window.location.href,
            ...options
        });
    };

    const openSignup = async (options) => {
        outsetaRef.current.auth.open({
            widgetMode: 'register',
            authenticationCallbackUrl: window.location.href,
            ...options
        });
    };

    const openProfile = async (options) => {
        outsetaRef.current.profile.open({ tab: 'profile', ...options });
    };

    return (
        <AuthContext.Provider
            value={{
                user,
                isLoading: status !== 'ready',
                logout,
                openLogin,
                openSignup,
                openProfile
            }}
        >
            {children}
        </AuthContext.Provider>
    );
}
